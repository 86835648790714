(function () {
  'use strict';
  /* eslint key-spacing: 0 */
  /* eslint camelcase: 0 */
  /* eslint quote-props: 0 */
  /* eslint no-useless-escape: 0 */
  /* eslint comma-dangle: error */

  angular.module('wml.portal')
    .constant('translations_nl_NL', {
      message              : {
        http      : {
          '401': 'U heeft geen toegang tot onderdeel',
          '403': 'U heeft geen toegang tot onderdeel',
          '404': 'Dit onderdeel bestaat niet (meer)',
          '429': 'U zoekt te snel. Probeer het opnieuw over 1 seconde',
          '500': 'Er is iets misgegaan, probeer opnieuw.',
          '-1' : 'Er is iets misgegaan, probeer het later opnieuw.',
          xxx  : 'Er is iets misgegaan, probeer het later opnieuw.'
        },
        backoffice: {
          'null' : 'Er is iets misgegaan. Neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met de klantenservice',
          '0'    : 'Er is iets misgegaan, probeer opnieuw.',
          '1000' : 'Niet alle benodigde informatie is ingevuld.',
          '1001' : 'Niet alle ingevulde gegevens zijn geldig',
          '1002' : 'Er is iets misgegaan, uw activatie link is onjuist.',
          '1003' : 'Er is iets misgegaan, uw activatie link is onjuist.',
          '1004' : 'Deze activatielink is verlopen. Registreer opnieuw.',
          '1005' : 'Er is iets misgegaan, probeer het later opnieuw.',
          '1006' : 'Uw account is succesvol geactiveerd. Gebruik uw e-mailadres en wachtwoord om in te loggen.',
          '1007' : 'Het e-mailadres is onjuist.',
          '1008' : 'Uw e-mailadres komt niet overeen.',
          '1009' : 'Uw wachtwoord voldoet niet aan de minimale vereisten.',
          '1010' : 'Uw wachtwoord komt niet overeen.',
          '1011' : 'Er is iets misgegaan, registreer opnieuw.',
          '1012' : 'Er is iets misgegaan, registreer opnieuw.',
          '1013' : 'Binnen enkele minuten ontvangt u een e-mail met een activatielink. Klik op de activatielink in deze e-mail om uw mijnWML account te activeren. Deze activatielink is maar 24 uur geldig. <br/><br/> Let op: mocht u de e-mail niet ontvangen, controleer dan de map met ongewenste e-mail (spam).',
          '1014' : 'U kunt het wachtwoord slechts een keer per dag wijzigen.',
          '1015' : 'Uw nieuwe wachtwoord wijkt niet genoeg af van het oude wachtwoord',
          '1016' : 'Uw wachtwoord is verlopen. Vraag een nieuw wachtwoord aan.',
          '1017' : 'Kies een ander wachtwoord dit wachtwoord is niet toegestaan.',
          '1018' : 'De wachtwoorden komen niet overeen.',
          '1019' : 'Er zijn invalide tekens gebruikt in uw wachtwoord.',
          '1020' : 'Uw wachtwoord mag niet beginnen met ? of !',
          '1021' : 'Uw wachtwoord dient minimaal uit 8 tekens te bestaan.',
          '1022' : 'De eerste 3 tekens van het wachtwoord moeten verschillend zijn.',
          '1023' : 'Uw wachtwoord mag niet beginnen met uw naam.',
          '1024' : 'Uw wachtwoord mag niet gelijk zijn aan wachtwoorden die u in het verleden heeft gebruikt.',
          '1025' : 'Uw wachtwoord komt voor in de lijst van niet toegestane wachtwoorden.',
          '1026' : 'Aanmelden niet meer mogelijk. Er zijn teveel mislukte pogingen.',
          '1027' : 'Klantnummer en adres komen niet overeen.',
          '1028' : 'Er is al een mijnWML account aanwezig voor dit klantnummer.',
          '1029' : 'Uw klantnummer is al gekoppeld aan een ander mijnWML account.',
          '1030' : 'Er is al een mijnWML account aanwezig voor e-mailadres.',
          '1031' : 'Gebruiker bestaat al.',
          '1032' : 'Geen gebruiker(s) gevonden voor opgegeven e-mailadres.',
          '1033' : 'Gebruiker wordt al geactiveerd.',
          '1034' : 'Gebruikersnaam bevat ongeldige tekens.',
          '1035' : 'Gebruikersaanvraag bestaat al.',
          '1036' : 'Vul een geldige activeringscode in.',
          '1037' : 'Telefoonnummer wijzigen mislukt. Wijzigingen niet opgeslagen.',
          '1038' : 'Het wachtwoord moet uit minimaal 8 tekens bestaan.',
          '1039' : 'Het wachtwoord moet ten minste 1 cijfer (0-9) bevatten.',
          '1040' : 'Het wachtwoord moet ten minste 1 letter (A-Z, a-z) bevatten.',
          '1041' : 'Het wachtwoord moet ten minste 1 speciaal teken bevatten.',
          '1042' : 'Het wachtwoord moet ten minste 1 kleine letter (a-z) bevatten.',
          '1043' : 'Het wachtwoord moet ten minste 1 hoofdletter (A-Z) bevatten.',
          '1044' : 'Het is niet gelukt om uw account op te halen',
          '1045' : 'Deze activatielink is al eens gebruikt. Het is niet mogelijk om deze nogmaals te gebruiken.',
          '1046' : 'Helaas kunt u zich nog niet registeren voor een mijnWML account. Neem voor vragen <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met onze klantenservice.',
          '2000' : 'De verplichte velden zijn niet juist ingevuld.',
          '2001' : 'Inloggen mislukt! De gebruikte combinatie van e-mailadres en wachtwoord is onjuist.',
          '2002' : 'Uw wachtwoord is hersteld. U ontvangt een e-mail met een activatielink. Klik op de activatielink om uw wachtwoord te wijzigen.',
          '2003' : 'Uw wachtwoord aanpassen is mislukt.',
          '2004' : 'Uw wachtwoord aanpassen is mislukt.',
          '2005' : 'Uw wachtwoord is gewijzigd.',
          '2006' : 'Het is niet gelukt om uw wachtwoord te resetten. Welllicht heeft u nog geen mijnWML account. Maak direct een mijnWML account aan door linksboven in het scherm op \"Registeren\" te klikken.',
          '2007' : 'Door meerdere ongeldige inlogpogingen binnen een korte periode is uw account tijdelijk geblokkeerd. Probeer het opnieuw vanaf {{time}}',
          '2008' : 'Door verdacht verkeer vanaf uw internetverbinding is deze tijdelijk geblokkeerd. Probeer het opnieuw vanaf {{time}}',
          '2009' : 'Er is iets fout gegaan bij het aanpassen van het e-mailadres. Probeer het later opnieuw.',
          '2010' : 'Er is iets fout gegaan bij het aanpassen van het e-mailadres. Probeer het later opnieuw.',
          '2011' : 'Uw account e-mailadres is succesvol bijgewerkt.',
          '2012' : 'Door meerdere ongeldige inlogpogingen binnen een korte periode is uw account tijdelijk geblokkeerd. Probeer het opnieuw vanaf {{time}}',
          '2013' : 'Dit adres kan helaas niet via mijnWML afgemeld worden. Wij vragen u <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met onze klantenservice. Wij verwerken dan uw afmelding voor dit adres.',
          '2014' : 'De gebruikte combinatie van klantnummer en adresgegevens is onjuist.',
          '2015' : 'Uw account e-mailadres en uw correspondentie e-mailadres zijn succesvol bijgewerkt. ',
          '2016' : 'Het door u ingevulde e-mailadres is ongeldig',
          '3000' : 'Momenteel kunnen er geen facturen worden opgehaald, probeer het later opnieuw.',
          '3001' : 'Momenteel kunnen er geen details van deze factuur worden opgehaald, probeer het later opnieuw.',
          '3002' : 'De verplichte velden zijn niet juist ingevuld.',
          '3003' : 'Één of meer facturen zijn al betaald.',
          '3004' : 'Één of meer invalide factuurnummers.',
          '3005' : 'Één of meer facturen niet gevonden.',
          '3006' : 'Het is niet mogelijk om meer dan 12 facturen tegelijkertijd af te rekenen.',
          '3007' : 'Het te betalen bedrag is te laag om via iDEAL af te rekenen.',
          '3008' : 'Er is iets misgegaan, het starten van de betaling is mislukt.',
          '3009' : 'Er is iets misgegaan, uw openstaand saldo kan niet worden opgehaald.',
          '4000' : 'Momenteel kunnen er geen adressen worden opgehaald, probeer het later opnieuw.',
          '5000' : 'Niet alle benodigde informatie is ingevuld.',
          '5001' : 'Er is geen adres gevonden voor de ingevulde gegevens.',
          '5002' : 'Uw postcode lijkt onjuist. Controleer of de postcode juist is ingevuld voordat u verder gaat.',
          '5003' : 'Het adres is onjuist.',
          '5004' : 'Uw adres is succesvol aangepast.',
          '5005' : 'Uw correspondentieadres mag niet gelijk zijn aan het af te melden leveringsadres.',
          '6000' : 'Er is iets misgegaan, probeer het later opnieuw.',
          '6001' : 'Niet alle benodigde informatie is ingevuld.',
          '6002' : 'Het wachtwoord mag niet langer zijn dan {{maxlength}} karakters',
          '7000' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '7001' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '7002' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '7003' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '7004' : 'Betalingsgegevens succesvol opgeslagen.',
          '8000' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '8001' : 'Niet alle benodigde informatie is ingevuld.',
          '8002' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '8003' : 'Er is iets misgegaan. Probeer het opnieuw of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> met ons op.',
          '8004' : 'Termijnbedrag succesvol opgeslagen.',
          '8005' : 'Het geadviseerde termijnbedrag kan niet worden opgehaald.',
          '8006' : 'Het maximale aantal verlagingen van het termijnbedrag per jaar is bereikt.',
          '8007' : 'Het gewenste termijnbedrag valt niet binnen de gestelde grenzen.',
          '8008' : 'Pas op, uw meterstand wijkt erg veel af van de vorige opname. Controleer of u uw meterstand juist heeft ingevuld.',
          '8009' : 'Helaas kunt u uw termijnbedrag op dit moment niet wijzigen. Dit kan pas weer nadat u de jaarafrekening ontvangen heeft.',
          '9000' : 'We hebben momenteel geen data voor een afspraak beschikbaar.',
          '9001' : 'We hebben momenteel geen tijden voor een afspraak beschikbaar.',
          '9002' : 'Niet alle benodigde informatie is ingevuld.',
          '9003' : 'Ongeldige postcode.',
          '9004' : 'Er is iets misgegaan, probeer opnieuw.',
          '9005' : 'Het is niet gelukt om een afspraak te maken. Probeer het later opnieuw.',
          '9006' : 'We hebben een afspraak voor u gemaakt, hiervoor ontvangt u een e-mail met informatie.',
          '9007' : 'De capaciteit van de door u gekozen watermeter is incorrect.',
          '9008' : 'Het is niet mogelijk om afspraken binnen 24 uur in te plannen',
          '9009' : 'Er kunnen geen tijdstippen opgehaald worden omdat het metertype niet bekend is.',
          '9010' : 'Het is niet gelukt om uw afspraak te annuleren. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '10000': 'Niet alle benodigde informatie is ingevuld.',
          '10001': 'Het e-mailadres is onjuist.',
          '10002': 'Het onderwerp is onjuist.',
          '10003': 'Geen geldige klantgegevens ingevuld.',
          '10004': 'Niet alle velden zijn correct ingevuld.',
          '10005': 'Het contactformulier is succesvol verstuurd.',
          '11000': 'Niet alle benodigde informatie is ingvuld.',
          '11001': 'Ongeldige postcode.',
          '11002': 'Pas op, uw meterstand wijkt erg veel af van de vorige opname. Controleer uw meterstand, en indien juist, geef de reden van afwijking op.',
          '11003': 'De door u doorgegeven meterstand wijkt erg veel af. Controleer uw meterstand, en indien juist, geef dan de reden van afwijking op. (U kunt ook een foto toevoegen).',
          '11004': 'De door u doorgegeven meterstand wijkt erg veel af. Controleer uw meterstand, en indien juist, geef dan de reden van afwijking op. (U kunt ook een foto toevoegen).',
          '11005': 'Uw meterstand is succesvol opgeslagen.',
          '11006': 'Er is iets misgegaan met opslaan van uw meterstand.',
          '11007': 'Het door u ingevulde opnamenummer en postcode is geen geldige combinatie',
          '11008': 'Er is al een meterstand doorgegeven voor deze opnameorder.',
          '11009': 'Het opgegeven e-mailadres kon niet worden opgeslagen.',
          '11010': 'Het is niet mogelijk de meterstand door te geven. Wij vragen u vriendelijk <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met onze klantenservice.',
          '11011': 'Het is niet mogelijk de meterstand door te geven. Wij vragen u vriendelijk <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met onze klantenservice.',
          '11013': 'De toegevoegde foto is er niet meer. Dit is waarschijnlijk veroorzaakt door een pagina verversing. Voeg dit opnieuw toe indien nodig.',
          '12000': 'Niet alle verplichte velden zijn ingevuld',
          '12001': 'De ingevulde postcode is ongeldig',
          '12002': 'Niet gelukt om aanpasing aan woning door te voeren',
          '12003': 'De wijziging is succesvol doorgevoerd',
          '12004': 'Pas op, de meterstand wijkt erg veel af van de bij ons laatst bekende meterstand. Controleer uw meterstand, en indien juist, klik op volgende. (U kunt ook een foto toevoegen).',
          '12005': 'Uw correspondentieadres mag niet gelijk zijn aan het af te melden leveringsadres.',
          '12006': 'Er is iets misgegaan. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12007': 'De door u ingevulde postcode en huisnummer komen niet overeen met de bij ons bekende gegevens.',
          '12008': 'De door u opgegeven meterstand is lager dan verwacht. Controleer uw meterstand, en indien juist, geef de reden van afwijking op.',
          '12100': 'Er is iets misgegaan. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12101': 'Er is iets misgegaan. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12102': 'Het bestand dat u wilt toevoegen is te groot. Dit mag maar maximaal 20MB zijn.',
          '12103': 'Er heeft zich al eerder iemand aangemeld op dit adres. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12104': 'Er is iets misgegaan. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12105': 'Er is iets misgegaan. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12106': 'Er is iets misgegaan. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '12107': 'Er heeft zich al eerder iemand afgemeld op dit adres. Gelieve <a href=\"/contact\" title=\"Klantenservice\">contact</a> op te nemen met de klantenservice.',
          '13000': 'Niet alle velden zijn correct ingevuld.',
          '13001': 'De door u ingevulde postcode is ongeldig.',
          '13002': 'U dient de voorwaarden te accepteren om verder te kunnen gaan.',
          '13003': 'Ongeldig correspondentieadres type. Uw adres mag alleen een huisadres of een postbus zijn.',
          '13004': 'Selecteer het aantal personen in uw huishouden.',
          '13005': 'De door u ingevulde e-mailadressen komen niet overeen.',
          '13006': 'De door u ingevulde wachtwoorden komen niet overeen.',
          '13007': 'De door u ingevuld combinatie van betaalmethode/-termijn is ongeldig.',
          '13008': 'Er is iets misgegaan bij het verwerken van uw aanvraag. Probeer het later nog eens.',
          '13009': 'Uw aanvraag is succesvol ontvangen. Het verwerken hiervan kan tot 8 werkdagen duren.',
          '13010': 'Het is alleen mogelijk u in te schrijven als huishouden of bedrijf.',
          '13011': 'De door u ingevulde postcode en huisnummer komen niet overeen met de bij ons bekende gegevens.',
          '14000': 'Niet alle velden zijn correct ingevuld',
          '14001': 'Er is iets misgegaan bij het opslaan van de contact persoon. Probeer het later nog eens.',
          '14002': 'De contactpersoon is succesvol toegevoegd.',
          '14003': 'De contactpersoon is succesvol verwijderd.',
          '14004': 'Het is niet gelukt om de contactpersoon te verwijderen.',
          '15000': 'Niet alle benodigde informatie is ingevuld.',
          '15004': 'Niet alle verplichte informatie is ingevuld.',
          '15005': 'Bedankt voor het insturen van uw sollicitatie.',
          '16000': 'Niet alle benodigde informatie is ingevuld.',
          '16004': 'Niet alle benodigde informatie is juist ingevuld.',
          '16005': 'Bedankt voor het insturen van uw aanvraag.',
          '17000': 'Niet alle benodigde informatie is juist ingevuld.',
          '17001': 'Ongeldige postcode ingevuld.',
          '18000': 'Niet alle benodigde informatie is juist ingevuld.',
          '18001': 'Schadeformulier is succesvol verzonden.',
          '19000': 'Wij werken hard aan het verbeteren van onze diensten. Op dit moment verrichten wij onderhoudswerkzaamheden. Excuses voor het ongemak.',
          '20000': 'Niet alle benodigde informatie is ingevuld',
          '20001': 'Niet alle velden zijn correct ingevuld',
          '20002': 'Zakelijk contactformulier is succesvol verzonden.',
          '21000': 'Niet alle benodigde informatie is juist ingevuld.',
          '21001': 'Ongeldige facturatiemethode ingegeven.',
          '21002': 'Niet gelukt om de facturatiemethode aan te passen.',
          '21003': 'Niet gelukt om de facturatiemethode aan te passen.',
          '21004': 'Facturatiemethode is succesvol bijgewerkt',
          '22000': 'Ongeldig export formaat',
          '23000': 'Niet alle velden zijn correct ingevuld',
          '23001': 'Er is iets misgegaan met het opslaan van uw reactie.',
          '23002': 'Uw reactie is succesvol opgeslagen.',
          '24000': 'Niet alle velden zijn correct ingevuld',
          '24001': 'Er is iets misgegaan. Probeer het later nog eens.',
          '24002': 'Uw aanvraag is succesvol verzonden',
          '25000': 'De betaling is niet gelukt of is door U afgebroken. Probeer het nogmaals.',
          '25001': 'Bedankt voor uw betaling. Het kan enkele dagen duren tot de betaling volledig verwerkt is door WML.',
          '25002': 'Door een fout kunnen we helaas niet vaststellen wat de actuele status van uw betaling is. Neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met de klantenservice voor meer informatie.',
          '26000': 'Niet alle benodigde informatie is ingevuld.',
          '26001': 'Er is iets misgegaan. Probeer het later opnieuw.',
          '26002': 'Er is iets misgegaan. Probeer het later opnieuw.',
          '26003': 'Bedankt voor uw inschrijving, u bent succesvol ingeschreven.',
          '26004': 'Bedankt voor uw stem.',
          '26005': 'Er is iets misgegaan. Probeer het later opnieuw.',
          '27000': 'Deze afbeelding is helaas te groot. De maximale grootte is 20MB.',
          '27001': 'Er ging iets mis probeer het later opnieuw',
          '28000': 'Niet alle benodigde informatie is juist ingevuld.',
          '28001': 'Er is iets misgegaan. Probeer het later opnieuw.',
          '28002': 'Betalingsregeling succesvol verzonden.'
        },
        custom    : {
          '1000': 'Uw gegevens zijn succesvol aangepast.',
          '1001': 'De verplichte velden zijn niet juist ingevuld.',
          '1002': 'Inloggen mislukt! De gebruikte combinatie van e-mailadres en wachtwoord is onjuist.',
          '1003': 'Er is iets misgegaan, de afspraak kan niet worden opgehaald. Probeer het opnieuw.',
          '1004': 'Het adres kan niet worden gevonden. Neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met de klantenservice.',
          '1005': 'Dit adres kan helaas (nog) niet online worden aangemeld. Neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met de klantenservice.',
          '1006': 'Het gebruik van cookies of andere opslag mogelijkheden is door uw browser niet toegestaan. Hierdoor zijn niet alle functionaliteiten beschikbaar.'
        }
      },
      gender               : {
        male  : 'Man',
        female: 'Vrouw'
      },
      country              : {
        nl: 'Nederland',
        de: 'Duitsland',
        be: 'Belgi\u00eb'
      },
      language             : {
        nl_NL: 'Nederlands',
        en_GB: 'English'
      },
      general              : {
        or       : 'of',
        remaining: 'Overige'
      },
      footer               : {
        nav: {
          direct              : 'Direct regelen',
          meter_readings      : 'Meterstanden doorgeven',
          pay_open_account    : 'Openstaande rekening betalen',
          forward_relocation  : 'Verhuizing doorgeven',
          edit_personal_info  : 'Persoonlijke gegevens wijzigen',
          price_checker       : 'Termijnbedrag checker',
          compare_use         : 'Verbruik vergelijken',
          download_annual_bill: 'Jaarafrekening downloaden',
          questions           : 'Vragen?',
          faq                 : 'Veel gestelde vragen',
          question            : 'Stel een vraag',
          contact_form        : 'Contactformulier',
          contact             : 'Contact',
          customer_service    : 'Klantenservice',
          report_complaint    : 'Klacht melden',
          report_damage       : 'Schade melden'
        }
      },
      header               : {
        customer_number         : 'Klantnummer',
        nav                     : {
          home                      : 'Home',
          about                     : 'Over WML',
          news                      : 'Nieuws',
          private                   : 'Particulieren',
          business                  : 'Zakelijk',
          sign_up                   : 'Registreren',
          login                     : 'Inloggen mijnWML',
          logout                    : 'Uitloggen',
          dashboard                 : 'Mijn overzicht',
          customer_dashboard        : 'Mijn persoonlijke gegevens',
          my_account                : 'Mijn account',
          customer_dashboard_company: 'Mijn bedrijfsgegevens',
          customer_invoices         : 'Mijn financiële gegevens',
          customer_usage            : 'Mijn verbruik',
          account_options           : 'Wisselen van klantnummer',
          choose_address            : 'Wisselen van leveringsadres',
          change_password           : 'Wachtwoord wijzigen',
          profile                   : 'Mijn account'
        },
        actions_title           : 'Direct regelen',
        myWML_actions_title     : 'Regelen via mijnWML',
        submit_meter_reading    : 'Jaarlijkse meterstand',
        submit_meter_reading_btn: 'Volgende',
        incidents               : {
          there_are                  : 'Er zijn',
          incidents_malfunctions     : 'Er {{malfunctions_prefix}} <span class=\"dot\">{{malfunctions}}</span> <u>storing{{malfunctions_plural}}</u>. Op <span class=\"dot\">{{planned_incidents}}</span> <u> plekken zijn er werkzaamheden.</u></span>',
          malfunctions_plural        : 'en',
          incidents_planned_incidents: 'werkzaamheden',
          malfunctions_prefix_single : 'is',
          malfunctions_prefix_plural : 'zijn',
          report: 'Storing melden'
        },
        video_modal                  : {
          view_video : 'Bekijk video'
        },
        button_register         : 'Registreren mijnWML',
        button_dashboard        : 'Naar mijnWML'
      },
      home                 : {
        malfunction_title: 'Actuele storingen',
        latest_news_title: 'Ons laatste nieuws',
        responses        : 'reactie(s)',
        more             : 'Lees meer'
      },
      incidents            : {
        modal_title    : 'Meest recente storingen',
        all_incidents  : 'Bekijk alle storingen & werkzaamheden',
        no_malfunctions: 'Momenteel zijn er geen ongeplande storingen. Klik op onderstaande button om alle geplande werkzaamheden in te zien',
        no_incidents   : 'Momenteel zijn er geen bekende storingen of werkzaamheden'
      },
      login                : {
        register_text  : 'Nog geen mijnWML account? <br/> Eerst registreren, dan inloggen!',
        page_title     : 'Inloggen bij mijnWML',
        email          : 'E-mailadres',
        password       : 'Wachtwoord',
        forgot_password: 'Wachtwoord vergeten?',
        login_btn      : 'Inloggen',
        signup_btn     : 'Registreren mijnWML',
        become_customer: 'Klant worden?',
        hints          : {
          email   : 'Neem hier het e-mailadres dat u aangemaakt heeft bij uw mijnWML account',
          password: 'Het wachtwoord van uw mijnWML account'
        }
      },
      signUp               : {
        page_title                       : 'Registreren mijnWML',
        customernumber                   : 'Klantnummer',
        customernumber_placeholder       : '12345678',
        postal_code                      : 'Postcode',
        postal_code_placeholder          : '0000 AB',
        house_number                     : 'Huisnummer',
        house_number_placeholder         : '12',
        house_number_addition            : 'toevoeging',
        house_number_addition_placeholder: 'A',
        email                            : 'E-mailadres',
        repeat_email                     : 'Herhaal e-mailadres',
        password                         : 'Wachtwoord',
        repeat_password                  : 'Herhaal wachtwoord',
        signup_btn                       : 'Registreren',
        login_btn                        : 'Inloggen mijnWML',
        become_customer                  : 'Klant worden bij WML?',
        hints                            : {
          customernumber : 'Dit vindt u terug op uw jaarafrekening of uw bankafschrift. Op uw afschrift begint het nummer met een K. Vul hier alleen het nummerieke gedeelte in. Bijvoorbeeld: 10999999',
          postal_code    : 'Hier dient u de postcode in te vullen die bij uw klantnummer hoort, dit kan een leverings of postadres zijn',
          house_number   : 'Uw huisnummer met een eventuele toevoeging',
          email          : 'Uw e-mailadres waarmee wij contact met u op kunnen nemen',
          repeat_email   : 'Ter verificatie van het bovenstaande e-mailadres',
          password       : 'Dit dient 8 karakters te zijn, 1 hoofdletter, kleine letter, cijfer en een speciaal teken.',
          repeat_password: 'Herhaal bovenstaand wachtwoord'
        },
        thank_you                        : {
          page_title: 'Bedankt voor uw registratie voor mijnWML',
          login     : 'Ga naar inloggen'
        }
      },
      account              : {
        page_title     : 'Selecteer klantnummer',
        intro_text     : 'U bent ingelogd als {{email}}. Bij dit e-mailadres horen meerdere klantnummers.<br />Van welke klant wilt u de drinkwater zaken regelen?',
        customer_number: 'Klantnummer',
        search         : 'Klantnummer zoeken',
        select_btn     : 'Selecteer klantnummer'
      },
      register             : {
        page_title                       : 'Klant worden bij WML',
        form_title                       : 'Leveringsadres',
        customer_type                    : 'Ik ben..',
        residence                        : 'Een huishouden',
        company                          : 'Een bedrijf',
        number_of_persons                : 'Aantal personen',
        street                           : 'Straatnaam',
        postal_code                      : 'Postcode',
        postal_code_placeholder          : '0000 AB',
        houseno_and_addtion              : 'Huisnummer & toevoeging',
        house_number_placeholder         : '12',
        house_number_addition_placeholder: 'A',
        place                            : 'Woonplaats',
        different_correspondence_address : 'Ik heb een afwijkend correspondentieadres',
        cancel                           : 'Annuleren',
        previous                         : 'Vorige',
        save                             : 'Volgende',
        hints                            : {
          customer_type    : 'Voor een huishouden en een bedrijf gelden andere invulvelden',
          number_of_persons: 'Hoeveel personen wonen er op het leveringsadres?',
          street           : '',
          postal_code      : 'Uw postcode van het adres waarmee u klant wilt worden',
          house_number     : 'Uw huisnummer met eventuele toevoeging',
          place            : ''
        },
        login: {
          hints: {
            postal_code: 'Uw postcode van het adres waarmee u klant wilt worden',
            house_number: 'Het huisnummer met eventuele toevoeging van het adres waarmee u klant wilt worden'
          }
        },
        correspondence                   : {
          form_title                        : 'Correspondentieadres',
          country                           : 'Land',
          select_a_country                  : 'Selecteer een land',
          option_NL                         : 'Nederland',
          option_BE                         : 'Belgie',
          option_DE                         : 'Duitsland',
          houseno_postal_code               : 'Postcode & Huisnr.',
          houseno_po_box                    : 'Postcode & Postbus',
          postal_code                       : 'Postcode',
          postal_code_placeholder           : '0000 AB',
          postal_code_placeholder_be        : '0000',
          postal_code_placeholder_de        : '00000',
          houseno_and_addtion               : 'Huisnummer & toevoeging',
          street                            : 'Straat',
          city                              : 'Plaats',
          po_box                            : 'Postbus',
          cancel                            : 'Vorige',
          save                              : 'Volgende',
          hints              : {
            select_a_country   : '',
            postal_code        : '',
            houseno_and_addtion: '',
            street             : 'Dit wordt automatisch gevuld als u de postcode en huisnummer ingevuld heeft',
            city               : 'De plaats wordt automatisch gevuld na invullen postcode en huisnummer',
            po_box             : ''
          }
        },
        personal_information             : {
          form_title            : 'Persoonsgegevens',
          salutation            : 'Aanhef',
          select                : 'Selecteer',
          initials              : 'Voorletters',
          insertion             : 'Tussenvoegsel',
          last_name             : 'Achternaam',
          birthday              : 'Geboortedatum',
          company_name          : 'Bedrijfsnaam',
          phonenumber           : 'Telefoonnummer',
          mobile_phonenumber    : 'Mobiele telefoonnummer',
          email                 : 'E-mailadres',
          invoice_email_checkbox: 'Ja, ik wil mijn factuur via e-mail ontvangen',
          customer_survey       : 'WML mag mij per e-mail vragen om deel te nemen aan een klantonderzoek',
          cancel                : 'Vorige',
          save                  : 'Volgende',
          hints                 : {
            salutation        : '',
            initials          : '',
            insertion         : '',
            last_name         : '',
            birthday          : '',
            company_name      : '',
            phonenumber       : '',
            mobile_phonenumber: '',
            email             : ''
          }
        },
        meter_reading                    : {
          form_title       : 'Meterstand',
          date_key_transfer: 'Datum sleuteloverdracht',
          meter_reading    : 'Meterstand',
          meter_number     : 'Meternummer',
          file_upload_description   : 'Afbeeldingen moeten kleiner zijn dan 20 MB. Toegestane bestandstypen gif, jpg, png.​',
          file_upload               : 'Foto van de watermeter toevoegen',
          file_upload_btn           : 'Bladeren',
          next             : 'Volgende',
          previous         : 'Vorige',
          hints            : {
            meter_reading: '',
            meter_number : 'Het meternummer vindt u op de watermeter en begint met 003 of 005, VIK of VI.'
          }
        },
        payment_method                   : {
          form_title          : 'Betaalwijze',
          payment_method      : 'Betaalwijze',
          automatic_collection: 'Automatische incasso',
          bank_transfer       : 'Overschrijving',
          payment_frequency   : 'Frequentie termijnbedrag',
          frequency_01        : 'Per maand',
          frequency_02        : 'Per 2 maanden',
          frequency_03        : 'Per 3 maanden',
          frequency_04        : 'Per 4 maanden',
          pay_date            : 'Datum afschrijving',
          date_16             : '16e van de maand',
          date_25             : '25e van de maand',
          iban                : 'IBAN nummer',
          save_btn            : 'Volgende',
          cancel_btn          : 'vorige',
          hints               : {
            payment_method   : '',
            payment_frequency: '',
            pay_date         : '',
            iban             : ''
          }
        },
        portal                           : {
          form_title     : 'mijnWML account',
          intro          : 'Als klant van WML kunt u gebruik maken van onze mijnWML omgeving. Hieronder kunt u een account aanmaken. Heeft u geen interesse, klik dan op stap overslaan.',
          email          : 'E-mailadres mijnWML account',
          repeat_email   : 'Herhaal e-mailadres mijnWML account',
          password       : 'Wachtwoord mijnWML account',
          repeat_password: 'Herhaal wachtwoord mijnWML account',
          next           : 'Volgende',
          previous       : 'Vorige',
          skip_step      : 'Stap overslaan',
          hints          : {
            email          : '',
            repeat_email   : '',
            password       : '',
            repeat_password: ''
          }
        },
        summary                          : {
          connection_address         : 'Leveringsadres',
          connection_address_edit    : 'Leveringsadres wijzigen',
          number_of_persons          : 'Aantal personen',
          address                    : 'Adres',
          meter_number               : 'Meternummer',
          correspondence_address     : 'Correspondentieadres',
          correspondence_address_edit: 'Correspondentieadres wijzigen',
          move_in_date_edit : 'Datum sleuteloverdracht wijzigen',
          future_connection_address : 'Toekomstige leveringsadres',
          future_address_edit      : 'Toekomstige leveringsadres wijzigen',
          po_box                     : 'Postbus',
          personal_information       : 'Persoonsgegevens',
          personal_information_edit  : 'Persoonsgegevens wijzigen',
          company_information        : 'Bedrijfsgegevens',
          company_information_edit   : 'Bedrijfsgegevens wijzigen',
          name                       : 'Naam',
          birthday                   : 'Geboortedatum',
          phone_number               : 'Telefoonnummer',
          mobile_phone_number        : 'Mobiel telefoonnummer',
          email                      : 'E-mailadres',
          emailInvoice               : 'Factuur via e-mail ontvangen',
          customerSurvey             : 'Deelnemen aan klantonderzoek',
          yes                        : 'ja',
          no                         : 'nee',
          meter_reading              : 'Meterstand',
          meter_reading_edit         : 'Meterstand wijzigen',
          date                       : 'Datum',
          payment_method             : 'Betaalwijze',
          payment_method_edit        : 'Betaalwijze wijzigen',
          frequency                  : 'Frequentie',
          pay_date                   : 'Datum afschrijving',
          iban                       : 'IBAN nummer',
          portal_account             : 'mijnWML account',
          portal_account_edit        : 'mijnWML account wijzigen',
          password                   : 'Wachtwoord',
          conditions                 : 'Ik ga akkoord met de <a href=\"/footer/algemene-voorwaarden\" title=\"algemene voorwaarden\" target=\"_blank\">algemene voorwaarden</a>',
          confirm                    : 'Bevestigen'
        },
        confirmation                     : {
          page_title        : 'Bedankt voor uw aanmelding als klant bij WML',
          confirmation_email: 'Uw aanvraag is succesvol ontvangen. Binnen enkele minuten ontvangt u een bevestiging van deze aanvraag per e-mail. Het verwerken hiervan kan tot 8 werkdagen duren. Aansluitend ontvangt u de overeenkomst.',
          page_future_title        : 'Bedankt voor uw aanmelding als toekomstige klant bij WML. ',
          confirmation_future_email: 'U ontvangt op {{date}} een herinnering per e-mail. Hierin vragen wij u de watermeterstand door te geven. Wij maken dan uw aanmelding compleet.'
        }
      },
      contact: {
        page_title: {
          main    : 'Heeft u een vraag, klacht of melding?',
          step1   :  'Stap 1 van 3 – Jouw vraag',
          step2   :  'Stap 2 van 3 – Leveradres',
          step3   :  'Stap 3 van 3 – Contactgegevens'
        },
        subject_choice                   : 'Kies het onderwerp',
        choose_option                    : 'maak een keuze',
        choose_address                   : 'Kies het adres',
        choose_premises                  : 'Uw leveradres',
        customernumber                   : 'Klantnummer',
        customernumber_description       : 'Om uw vraag sneller te kunnen beantwoorden vul hier uw klantnummer in, dit vindt u links bovenaan op uw factuur.',
        customernumber_placeholder       : '12345678',
        postal_code                      : 'Postcode',
        postal_code_placeholder          : '0000 AB',
        house_number                     : 'Huisnummer',
        house_number_placeholder         : '12',
        house_number_addition            : 'toevoeging',
        house_number_addition_placeholder: 'A',
        no_customer_number               : 'Geen klantnummer / klantnummer onbekend',
        email                            : 'E-mailadres',
        repeat_email                     : 'Herhaal e-mailadres',
        name                             : 'Naam',
        street                           : 'Straat',
        place                            : 'Plaats',
        phone                            : 'Telefoonnummer',
        mobile_phone                     : 'Mobiel telefoonnummer',
        question                         : 'Uw vraag / opmerking',
        research                         : 'WML mag mij per e-mail vragen om deel te nemen aan een klantonderzoek.',
        next_btn                         : 'Volgende',
        previous_btn                     : 'Vorige',
        cancel_btn                       : 'Annuleren',
        send_btn                         : 'Versturen',
        file_upload                      : 'Bijlage(n) toevoegen (optioneel)',
        file_upload_btn                  : 'Bladeren',
        file_upload_description          : 'Je kan een bestand toevoegen om je probleem of je vraag te verduidelijken. Je kan maximaal 20 MB opladen. Dit type document of beeld kan je zonder problemen opladen: gif, jpg, png, pdf, doc, docx, xls, xlsx, odt, ods.​',
        other_address: {
          check: {
            label: 'Heeft uw vraag betrekking op bovenstaand adres?',
            yes: 'Ja',
            no: 'Nee'
          },
          postal_code: 'Postcode',
          house_number: 'Huisnummer',
          house_number_addition: 'toevoeging',
          street: 'Straat',
          place: 'Plaats'
        },
        time_bracket                     : {
          label: 'Wanneer bent u overdag het best bereikbaar?',
          morning: 'In de ochtend',
          afternoon: 'In de middag',
          no_preference: 'Geen voorkeur'
        },
        subject                          : {
          moving     : 'verhuizen',
          complaint  : 'klacht',
          meter      : 'meter',
          malfunction: 'storing',
          water      : 'water',
          shutoff    : 'afsluiting',
          other      : 'overig',
          damage     : 'schade',
          content: 'Naar aanleiding van uw vraag nemen we telefonisch of per e-mail contact met u op. Vul uw gegevens alsjeblieft zo volledig mogelijk in. Zo kunnen we u beter helpen.​'
        },
        business_subject                 : {
          connection          : 'Aansluiting',
          irrigation          : 'Beregening',
          smartmeter          : 'Slimme Meter',
          groundwatermanagment: 'Grondwaterbeheer',
          firesafety          : 'Brandveiligheid',
          sourcemanagement    : 'Bronnenbeheer',
          temporarywater      : 'Tijdelijk water',
          waterwall           : 'Waterwand',
          watertap            : 'Watertap',
          privateproperty     : 'Eigen terrein',
          other               : 'Overig'
        },
        hints                            : {
          customernumber: 'Dit vindt u terug op uw jaarafrekening of uw bankafschrift. Op uw afschrift begint het nummer met een K. Vul hier alleen het nummerieke gedeelte in. Bijvoorbeeld: 10999999',
          postal_code   : '',
          house_number  : '',
          email         : 'Het e-mailadres waarmee wij voor deze vraag, klacht of melding contact met u kunnen opnemen',
          repeat_email  : '',
          phone         : 'Vul hier alleen de cijfers van uw telefoonnummer in. Zonder streepjes, spaties, punten e.d.',
          mobile_phone  : 'Vul hier alleen de cijfers van uw telefoonnummer in. Zonder streepjes, spaties, punten e.d.'
        },
        thank_you                        : {
          page_title: 'Bericht verzonden',
          text      : 'Bedankt voor het plaatsen van uw vraag of opmerking. U ontvangt hiervan een bevestiging per e-mail.',
          btn       : 'Terug naar mijnWML'
        }
      },
      business_contact     : {
        page_title    : 'Contactformulier zakelijk',
        company       : 'Bedrijfsnaam',
        contact_person: 'Contactpersoon',
        phone_number  : 'Telefoonnummer',
        email         : 'E-mailadres',
        subject       : 'Onderwerp',
        option_choice : 'Maak een keuze',
        message       : 'Vraag of opmerking',
        btn           : 'Verstuur contact formulier'
      },
      customer             : {
        dashboard_title                : 'Mijn persoonlijke gegevens',
        dashboard_title_company        : 'Mijn bedrijfsgegevens',
        block_title                    : 'Persoonsgegevens',
        block_title_company            : 'Bedrijfsgegevens',
        customernumber                 : 'Klantnummer',
        name                           : 'Naam',
        birthday                       : 'Geboortedatum',
        phonenumber                    : 'Telefoonnummer',
        mobile_phonenumber             : 'Mobiel telefoonnummer',
        correspondence_email           : 'Correspondentie e-mailadres',
        customer_surveys               : 'Deelnemen aan klantonderzoeken',
        newsletter                     : 'Digitale nieuwsbrief ontvangen',
        change_customer                : 'Persoonsgegevens wijzigen',
        change_customer_company        : 'Bedrijfsgegevens wijzigen',
        my_correspondence              : 'Correspondentiegegevens',
        correspondence_address         : 'Correspondentieadres',
        po_box                         : 'Postbus',
        change_correspondence_address  : 'Correspondentieadres wijzigen',
        yes                            : 'Ja',
        no                             : 'Nee',
        billing_information_block_title: 'Factuurgegevens',
        invoice_email                  : 'E-mailadres voor facturen',
        billing_information_edit       : 'Factuurgegevens wijzigen',
        invoice_sendcontrol            : 'Verzendwijze facturen',
        sendcontrol                    : {
          option_0: 'post',
          option_3: 'digitaal'
        },
        billing_information            : {
          page_title       : 'Factuurgegevens wijzigen',
          save             : 'Opslaan',
          cancel           : 'Annuleren',
          email            : 'E-mailadres voor facturen',
          sendcontrol_label: 'Factuur digitaal ontvangen?',
          sendcontrol      : {
            option_0: 'Nee',
            option_3: 'Ja'
          },
          hints            : {
            email      : 'Dit e-mailadres zal gebruikt worden voor het versturen van facturen',
            sendcontrol: ''
          }
        },
        connection_addresses           : 'Leveringsadres(sen)',
        download_csv                   : 'Download overzicht als csv',
        connection_meter_number        : 'Meternr.',
        smart_meter_link               : 'Bekijk gegevens van slimme meter',
        connection_address             : 'Adres',
        my_invoice                     : 'Factuurgegevens',
        email_invoice                  : 'E-mailadres voor facturen',
        change_invoice_information     : 'Factuur gegevens wijzigen',
        contact_persons                : {
          title: 'Contactgegevens',
          type : {
            general       : 'Correspondentie',
            administration: 'Facturatie',
            incidents     : 'Storing',
            newsletter    : 'Nieuwsbrief',
            hints: {
              general       : 'Correspondentie contact',
              administration: 'Administratie contact',
              incidents     : 'Storing contact',
              newsletter    : 'Nieuwsbrief contact'
            }
          },
          name : 'Naam',
          phone: 'Telefoon',
          mobile_phone: 'Mobiel',
          email: 'E-mail'
        },
        moving_away                    : 'Gaat u verhuizen?',
        moving_away_text               : 'Gaat u verhuizen, geef dan hier uw adreswijziging door. U kunt uw nieuwe woning aanmelden of uw oude woning afmelden. Dit gaat alleen als de sleuteloverdracht is geweest.',
        moving_away_corporation        : 'Verhuizing doorgeven?',
        moving_away_text_corporation   : 'Geef dan hier de adreswijziging door. U kunt een nieuwe woning aanmelden of een oude woning afmelden. Dit gaat alleen als de sleuteloverdracht is geweest.',
        submit_moving_away             : 'Verhuizing doorgeven',
        sign_up_residence              : 'Nieuwe woning aanmelden',
        sign_out_residence             : 'Oude woning afmelden',
        edit                           : {
          page_title          : 'Persoonsgegevens wijzigen',
          page_title_company  : 'Bedrijfsgegevens wijzigen',
          salutation          : 'Aanhef',
          birthday            : 'Geboortedatum',
          phonenumber         : 'Telefoonnummer',
          mobile_phonenumber  : 'Mobiel telefoonnummer',
          correspondence_email: 'Correspondentie e-mailadres',
          update_account_email: 'Dit e-mailadres is ook mijnWML account e-mailadres.',
          customer_surveys    : 'WML mag mij per e-mail vragen om deel te nemen aan een klantonderzoek.',
          newsletter          : 'WML mag mij per e-mail de digitale nieuwsbrief toesturen.',
          yes                 : 'Ja',
          no                  : 'Nee',
          cancel              : 'Annuleren',
          save                : 'Opslaan',
          hints               : {
            salutation          : '',
            birthday            : '',
            phonenumber         : 'Vul hier alleen de cijfers van uw telefoonnummer in. Zonder streepjes, spaties, punten e.d.',
            mobile_phonenumber  : 'Vul hier alleen de cijfers van uw telefoonnummer in. Zonder streepjes, spaties, punten e.d.',
            correspondence_email: 'Via dit e-mailadres ontvangt u alle post van WML',
            customer_surveys    : '',
            newsletter          : ''
          }
        },
        correspondence              : {
          page_title                : 'Correspondentieadres wijzigen',
          current_correspondence    : 'Huidige correspondentieadres',
          new_correspondence        : 'Nieuw correspondentieadres',
          other                     : 'Anders,',
          country                   : 'Land',
          select_a_country          : 'Selecteer een land',
          option_NL                 : 'Nederland',
          option_BE                 : 'Belgie',
          option_DE                 : 'Duitsland',
          houseno_postal_code       : 'Postcode & Huisnr.',
          houseno_po_box            : 'Postcode & Postbus',
          postal_code               : 'Postcode',
          postal_code_placeholder   : '0000 AB',
          postal_code_placeholder_be: '0000',
          postal_code_placeholder_de: '00000',
          houseno_and_addtion       : 'Huisnummer & toevoeging',
          street                    : 'Straat',
          city                      : 'Plaats',
          po_box                    : 'Postbus',
          cancel                    : 'Annuleren',
          save                      : 'Opslaan',
          hints                     : {
            select_a_country   : '',
            postal_code        : '',
            houseno_and_addtion: '',
            street             : 'Dit wordt automatisch gevuld als u de postcode en huisnummer ingevuld heeft',
            city               : 'De plaats wordt automatisch gevuld na invullen postcode en huisnummer',
            po_box             : ''
          }
        },
        wfl: {
          text: 'Namens Water for Life hartelijk dank voor uw donatie. Hiermee helpt u mee om schoon drinkwater en sanitaire voorzieningen wereldwijd toegankelijk te maken voor iedereen.',
          amount_text: 'Bedrag dat u jaarlijks doneert',
          donation: {
            edit: "Bedrag wijzigen",
            stop: "Stopzetten donatie"
          }
        }
      },
      contact_persons      : {
        page_title     : 'Contactpersoon toevoegen',
        page_title_edit: 'Contactpersoon wijzigen',
        firstName      : 'Voornaam',
        insertion      : 'Tussenvoegsel',
        lastName       : 'Achternaam',
        email          : 'E-mailadres',
        phone          : 'Telefoonnummer',
        mobile_phone   : 'Mobiel telefoonnummer',
        hints          : {
          firstName: '',
          insertion: '',
          lastName : '',
          email    : '',
          phone    : '',
          mobile_phone: ''
        },
        save           : 'Opslaan',
        cancel         : 'Annuleren'
      },
      paymentmethods       : {
        bank_transfer       : 'Overschrijving',
        automatic_collection: 'Automatische incasso',
        refund              : 'Terug betaling',
        clearing            : 'Verrekening'
      },
      invoices             : {
        dashboard            : {
          dashboard_title           : 'Mijn financiële gegevens',
          title_connection_addresses: 'Mijn Leveringsadres(sen)',
          connection_meter_number   : 'Meternr.',
          connection_address        : 'Adres',
          connection_paymentmethod  : 'Betaalwijze',
          connection_installment    : 'Termijnbedrag',
          title_invoices            : 'Mijn laatste facturen',
          date_invoices             : 'Datum',
          description_invoices      : 'Omschrijving',
          amount_invoices           : 'Bedrag',
          view_invoices             : 'Bekijk alle betalingen',
          no_invoices               : 'Er zijn geen facturen',
          no_remaining_invoices     : 'Er zijn geen openstaande facturen',
          title_remaining_amount    : 'Mijn openstaand saldo',
          view_remaining_amount     : 'Bekijk alle openstaande facturen',
          view_payment_arrangement  : 'Betalingsregeling',
          postal_filter             : 'Zoeken op postcode',
          change_payment_method     : 'Betaalwijze wijzigen',
          change_installment_amount : 'Termijnbedrag wijzigen',
          iban: "IBAN nummer"
        },
        filters              : {
          year              : 'Jaar',
          connection_address: 'Alle leveringsadressen',
          invoice_number    : 'Factuurnummer',
          mobile_filter_txt : 'Filter uw facturen'
        },
        page_title           : 'Mijn betalingen',
        page_title_remaining : 'Mijn openstaand saldo',
        no_remaining_invoices: 'Er zijn geen openstaande facturen',
        no_invoices          : 'Er zijn geen facturen',
        download_csv         : 'Download overzicht als csv',
        payment_message      : 'Wilt u niet het hele openstaand saldo betalen? Vink de posten uit die u op dit moment niet wilt betalen.',
        pay_btn              : 'Nu betalen',
        pay_total            : 'Totaal',
        table                : {
          date                 : 'Datum',
          invoice_number       : 'Factuurnummer',
          description          : 'Omschrijving',
          amount               : 'Bedrag',
          status               : 'Status',
          payment_method       : 'Betaalwijze',
          state                : 'Status',
          due_date             : 'Vervaldatum',
          paid                 : 'Betaald',
          refunded             : 'Gestorneerd',
          payment_in_process   : 'In behandeling',
          standard_pay_method  : 'iDEAL',
          popover_without_costs: 'Factuur:',
          popover_total_costs  : 'Kosten:',
          popover_pay_in_order : 'Facturen dienen betaald te worden op volgorde van datum',
          details_without_costs: 'Factuur',
          details_total_costs  : 'Kosten'

        },
        pagination           : {
          page_counter: '<strong>{{range}}</strong> van <strong>{{total}}</strong> facturen',
          previous    : 'Vorige',
          next        : 'Volgende'
        },
        descriptions         : {
          PA     : 'Afrekening',
          TA     : 'Tussentijdse afrekening',
          SA     : 'Eindafrekening',
          HA     : 'Handmatige factuur',
          FA     : 'Jaarafrekening',
          VS     : 'Termijnbedrag',
          VA     : 'Verzamelfactuur',
          NW     : 'Niet water factuur',
          AK     : 'Aanmaankosten',
          BR     : 'Betalingsregeling',
          AG     : 'Betaling',
          UNKNOWN: 'Onbekend'
        },
        paymentResult: {
          success: "Bedankt voor uw betaling",
          failed: "Er is iets misgegaan"
        },
        payment_arrangement: {
          page_title: 'Betalingsregeling',
          total_amount_remaining: 'Totaal bedrag',
          payment_frequency: 'Termijnen',
          payment_frequency_amount: 'Bedrag per termijn',
          iban_number: 'IBAN Nummer',
          pay_with_ai: 'Betaal deze termijnen en uw toekomstige vorderingen via automatische incasso.',
          save_btn: 'Opslaan',
          cancel_btn: 'Annuleren',
          choose_option: 'Maak een keuze'
        }
      },
      change_payment_method: {
        page_title          : 'Betaalwijze wijzigen',
        payment_method      : 'Betaalwijze',
        automatic_collection: 'Automatische incasso',
        bank_transfer       : 'Overschrijving',
        payment_frequency   : 'Frequentie termijnbedrag',
        frequency_01        : 'Per maand',
        frequency_02        : 'Per 2 maanden',
        frequency_03        : 'Per 3 maanden',
        frequency_04        : 'Per 4 maanden',
        pay_date            : 'Datum afschrijving',
        date_16             : '16e van de maand',
        date_25             : '25e van de maand',
        iban                : 'IBAN nummer',
        change_iban         : 'IBAN/Banknummer wijzigen',
        save_btn            : 'Opslaan',
        cancel_btn          : 'Annuleren',
        hints               : {
          payment_method   : '',
          payment_frequency: '',
          pay_date         : '',
          iban             : ''

        }
      },
      usage                : {
        dashboard          : {
          dashboard_title           : 'Mijn verbruik',
          title_usage_scan          : 'Verbruiksscan',
          usage_scan_intro          : 'Vul uw meterstanden in en bekijk of uw termijnbedrag nog voldoet! Makkelijk uw termijnbedrag bijstellen en nooit meer teveel betalen!',
          usage_scan_btn            : 'Verbruiksscan',
          usage_scan_popup_title    : 'Voor welk leveringsadres wilt u de verbruiksscan invullen?',
          title_water_save_check    : 'Waterbespaarcheck',
          water_save_check_intro    : 'Doe de Waterbespaarcheck en kijk hoe bewust u met Limburgs drinkwater bent',
          water_save_check_btn      : 'Waterbepsaarcheck',
          connection_meter_number   : 'Meternr.',
          connection_address        : 'Adres',
          title_meter_reading       : 'Een meteropname kaart ontvangen?',
          meter_reading_intro       : 'Dan kunt u hier online uw meterstand doorgeven',
          meter_reading_popup_title : 'Voor welk leveringsadres wilt u de meterstand invullen?',
          meter_reading_address     : 'Adres',
          meter_reading_meter_number: 'Meternr.',
          meter_reading_btn         : 'Meterstanden doorgeven'
        },
        meter_readings     : {
          page_title    : 'Meterstand invullen',
          btn_next      : 'Volgende',
          recording_date: 'Opnamedatum',
          meter_reading : 'Meterstand',
          meter_number  : 'Meternummer',
          optional_email: 'Stuur mij hierover een bevestiging',
          cancel        : 'Annuleren',
          old_meter_vik : '\"Oude\" standaard meter',
          meter_vik     : 'Standaard meter',
          old_meter_vi  : '\"Oude\" grote meter',
          meter_vi      : 'Grote meter',
          hints         : {
            recording_date: '',
            meter_reading : 'Vul uw meterstand in (alleen de getallen vóór de komma).',
            meter_number : 'Het meternummer vindt u op de watermeter en begint met 003 of 005, VIK of VI.'
          }
        },
        compare            : {
          page_title         : 'Verbruik vergelijken',
          recording_date     : 'Opnamedatum',
          meter_reading      : 'Meterstand',
          usage              : 'Verbruik',
          year_usage         : 'Jaarverbruik',
          current_installment: 'Huidig termijnbedrag',
          advised_installment: 'Geadviseerd termijnbedrag',
          effective_date     : 'Ingangsdatum',
          compare_text       : 'Op basis van de door u doorgevoerde meterstanden zal uw<br/> jaarverbruik uitkomen op <strong>{{usage}} m3 per jaar</strong>.',
          checkbox           : 'Het geadviseerde termijnbedrag gebruiken',
          cancel             : 'Vorige',
          save               : 'Termijnbedrag wijzigen',
          hints              : {
            advised_installment: 'Uw geadviseerde termijn bedrag',
            checkbox           : 'Het geadviseerde termijn bedrag gebruiken'
          }
        },
        installment_change : {
          page_title         : 'Termijnbedrag wijzigen',
          frequency_01       : 'per maand',
          frequency_02       : 'per 2 maanden',
          frequency_03       : 'per 3 maanden',
          frequency_04       : 'per 4 maanden',
          frequency_12       : 'jaarlijksvoorschot',
          limits             : 'Het termijnbedrag mag niet minder dan {{lower}} en niet hoger dan {{upper}} zijn.',
          current_installment: 'Uw huidige termijnbedrag is',
          new_installment    : 'Gewenst termijnbedrag',
          meter_readings     : 'Hulp door meterstanden invullen',
          cancel             : 'Annuleren',
          save               : 'Opslaan',
          hints              : {
            new_installment: 'Geef uw gewenste termijn bedrag op',
            meter_readings : 'Bereken termijn bedrag aan de hand van verbruik'
          }
        },
        installment_changed: {
          page_title       : 'U heeft uw termijnbedrag gewijzigd',
          personalized_text: 'Het termijnbedrag voor het leveringsadres <strong>{{address}}</strong> is aangepast naar <strong>{{installment}}</strong>',
          btn_finish       : 'Naar overzicht'
        },
        chart              : {
          title           : 'Uw waterverbruik per periode',
          compare_title   : 'Vergelijk uw verbruik met het gemiddelde verbruik van een huishouden met:',
          text            : 'In Nederland verbruikt een 1-persoons huishouden gemiddeld 128 liter water per dag. Doe de <a href=\"https://limburgsdrinkwater.nl/waterbespaarcheck\" title=\"Waterbespaarcheck\">Waterbespaarcheck</a> en kijk hoe bewust u met Limburgs drinkwater bent.',
          options         : {
            option1: '7 personen',
            option2: '6 personen',
            option3: '5 personen',
            option4: '4 personen',
            option5: '3 personen',
            option6: '2 personen',
            option7: '1 persoon'
          },
          smart_meter_text: 'Dit leveringsadres maakt gebruik van een slimme meter.',
          smart_meter_link: 'Bekijk hier de gegevens van de slimme meter',
          table           : {
            recording_period: 'Periode',
            meter_reading   : 'Meterstand',
            usage           : 'Verbruik',
            reading_type    : 'Opnamesoort'
          },
          graph_labels: {
            customer: '<span><i class=\"fa fa-user-circle-o\" title=\"Eigen opname\"></i> Eigen opname</span>',
            wml: '<span><i class=\"fa fa-wrench\" title=\"Monteur\"></i> Monteur</span>',
            estimated: '<span><i class=\"fa fa-magic\" title=\"Schatting\"></i> Schatting</span>'
          },
          button          : 'Bekijk alles',
          no_history      : 'Uw verbruikshistorie kan op dit moment nog niet getoond worden. Hiervoor hebben we tenminste één meterstand nodig.',
          no_history_alt  : 'Op uw adres is de watermeter onlangs vervangen. Wij kunnen u op dit moment helaas dan ook geen historie in waterverbruik laten zien. U kunt op uw jaarfacturen het verbruik van het betreffende jaar zien. De jaarfacturen vindt u onder ‘<a href=\"/mijn-financiele-gegevens\" title=\"Mijn financiële gegevens\">Mijn financiële gegevens</a>’.'
        }
      },
      meter_readings       : {
        page_title                : 'Meterstand doorgeven',
        last_reading              : 'Uw laatste meterstand was <span class=\"customer-label\">{{lastReading}} m<small>3</small></span> en is doorgegeven op {{readingDate}}',
        btn_next                  : 'Volgende',
        yes                       : 'Ja',
        no                        : 'Nee',
        recording_date            : 'Opnamedatum',
        deviation_code            : 'Reden van afwijking',
        deviation_reason          : 'Toelichting',
        file_upload_description   : 'Afbeeldingen moeten kleiner zijn dan 20 MB. Toegestane bestandstypen gif, jpg, png.​',
        file_upload               : 'Foto van de watermeter toevoegen',
        file_upload_btn           : 'Bladeren',
        meter_reading             : 'Meterstand',
        meter_number              : 'Meternummer',
        keep_installment_amount   : 'Ja, ik wil graag hetzelfde termijnbedrag blijven betalen als afgelopen jaar.',
        optional_email            : 'Wilt u een bevestiging van de door u doorgegeven meterstand ontvangen?',
        cancel                    : 'Annuleren',
        email_address_confirmation: 'E-mailadres voor bevestiging',
        hints                     : {
          recording_date: '',
          meter_reading : 'Vul uw meterstand in (alleen de getallen vóór de komma).',
          meter_number : 'Het meternummer vindt u op de watermeter en begint met 003 of 005, VIK of VI.',
          keep_installment_amount: 'Uw termijnbedrag wordt na de jaarafrekening niet aangepast.',
          deviation_code: '',
          deviation_reason: ''
        },
        deviations                : {
          deviation_8 : 'Lekkage',
          deviation_9 : 'Pand onbewoond geweest',
          deviation_10: 'Meer bewoners',
          deviation_13: 'Eigen bron/minder productie',
          deviation_18: 'Meer gesproeid',
          deviation_19: 'Vijver/zwembad gevuld',
          deviation_20: 'Meermalig geschat geweest',
          deviation_21: 'Meer productie',
          deviation_22: 'Lekkage gehad meer dan een jaar geleden',
          deviation_23: 'Minder bewoners',
          deviation_24: 'Minder gesproeid',
          deviation_25: 'Vijver/zwembad verwijderd/niet gevuld',
          deviation_26: 'Minder productie',
          deviation_27: 'Meter verwisseld',
          deviation_28: 'Meter defect',
          deviation_99: 'Andere reden, vul toelichting in'
        },
        recording_number          : {
          page_title              : 'Meterstand doorgeven',
          number                  : 'Opnamenummer',
          postal_code             : 'Postcode',
          placeholder_postal_code : '0000 AB',
          cancel                  : 'Annuleren',
          btn_next                : 'Volgende'
        },
        email: {
          page_title: 'Meterstand doorgeven',
          content: 'Wilt u een bevestiging van uw doorgegeven meterstand, vul dan hier uw e-mailadres in.',
          email_address_confirmation: 'E-mailadres',
          cancel: 'Vorige',
          save: 'Bevestigen'
        },
        summary: {
          page_title: 'Meterstand doorgeven',
          personal_data_title: 'Uw gegevens',
          recording_number: 'Opnamenummer',
          postal_code: 'Postcode',
          address: 'Leveringsadres',
          meter_number: 'Meternummer',
          email: {
            title: 'Bevestiging',
            edit: 'E-mail adres wijzigen',
            none: 'U heeft gekozen om geen bevestiging e-mail te ontvangen van uw doorgegeven stand',
            emailadress: 'U ontvangt een bevestiging op'
          },
          meter_reading: {
            title: 'Meterstand',
            date: 'Datum',
            reading: 'Meterstand',
            edit: 'Meterstand wijzigen'
          },
          cancel: 'Vorige',
          save: 'Bevestigen'
        },
        thank_you                 : {
          page_title: 'Bedankt voor het doorgeven van uw meterstand',
          btn       : 'Afronden',
          text      : 'U krijgt een bevestiging hiervan per e-mail wanneer u een e-mailadres heeft ingevuld.<br/><br/> Let op: mocht u de e-mail niet ontvangen, controleer dan de map met ongewenste e-mail (spam).',
          no_email_text: 'U krijgt geen bevestiging hiervan.',
          wfl: {
            title: 'Doneren aan Water for Life',
            p1: 'Samen met andere Nederlandse drinkwaterbedriven ondersteunt WML Stichting Water for Life in haar missie om schoon drinkwater en sanitaire voorzieningen wereldwijd voor iedereen toegankelijk te maken. Met veel enthousiasme zetten wij ons in om fondsen te werven voor het goede werk van Water for Life. Maar dat kunnen we natuurlijk niet alleen. Helpt u ook mee?',
            p2: 'Met uw donatie, hoe groot of klein ook, draagt u bij aan toegang tot schoon en betrouwbaar drinkwater en een toilet voor iedereen ter wereld. Voorbeeld: voor 18 Euro help je al 1 persoon aan schoon drinkwater.',
            p3: 'Welk bedrag wilt u jaarlijks doneren?',
            p4: 'U betaalt de donatie op dezelfde manier en van hetzelfde rekeningnummer als de waterrekening.',
            p5: 'Maakt u gebruik van automatische incasso? Dan wordt ook deze donatie automatisch afgeschreven.'
          }
        }
      },
      my_dashboard         : {
        dashboard_title         : 'Mijn overzicht',
        meter_reading_title     : 'Jaarlijkse meterstand doorgeven',
        meter_reading_text      : 'Heeft u een meternopname kaart ontvangen? Dan kunt u hier makkelijk en snel uw meterstand doorgeven.',
        meter_reading_button    : 'Meterstanden doorgeven',
        account                 : {
          active_customer: 'Actief klantnummer',
          customer_number: 'Klantnummer',
          name           : 'Naam',
          switch_customer: 'Wisselen van klantnummer'
        },
        corporation     : {
          dashboard_title: 'Gekozen Leveringsadres wisselen',
          switch_address : 'Ander leveringsadres kiezen',
          csv_overview   : 'Leveringsadressen overzicht',
          csv_text       : 'Download hier een overzicht van alle leveringsadressen.',
          csv_btn        : 'Download CSV'
        },
        msa_make_appointment    : {
          title : 'Afspraak meterwisseling maken',
          text  : 'Heeft u een oproep ontvangen om uw watermeter te laten wisselen? Maak dan hier een afspraak.',
          button: 'Afspraak maken'
        },
        msa_planned_appointments: {
          title: 'Mijn geplande afspraken',
          label: 'afspraak wijzigen',
          empty: 'Geen omschrijving schadebeeld meegegeven.',
          delete: 'afspraak verwijderen'
        },
        customer_invoices       : {
          title    : 'Mijn financiële gegevens',
          remaining: 'Totaal openstaand saldo',
          button   : 'Bekijk alles',
          view_remaining_amount: 'Betaal via IDEAL'
        },
        correspondence          : {
          address: 'Correspondentieadres',
          button : 'Bekijk alles'
        },
        usage                   : {
          title: 'Mijn verbruik'
        },

        popup: {
          msa_popup_title   : 'Voor welk leveringsadres wilt u de meterwissel inplannen?',
          notify_no         : 'Servicemeldingnummer',
          connection_address: 'Adres'
        }
      },
      forgot_password      : {
        page_title : 'Wachtwoord vergeten',
        email      : 'E-mailadres',
        forgot_btn : 'Verstuur e-mail',
        please_wait: 'Een momentje terwijl we je wachtwoord herstellen. U wordt binnen enkele ogenblikken automatisch doorgestuurd.',
        text       : '<p>Vul hieronder uw e-mailadres in en klik op de knop \"Verstuur e-mail\".</p><p>U ontvangt binnen enkele minuten een e-mail waarmee u een nieuw wachtwoord kunt instellen.</p>',
        emailSent  : 'Uw wachtwoord is hersteld. U ontvangt een e-mail met een activatielink. Klik op de activatielink om uw wachtwoord te wijzigen.',
        hints      : {
          email: 'Vul het e-mailadres in van uw mijnWML account'
        }
      },
      profile              : {
        dashboard      : {
          page_title         : 'mijnWML account informatie',
          email              : 'Account e-mail',
          password           : 'Wachtwoord',
          btn_change_password: 'Wachtwoord wijzigen',
          btn_change_email   : 'E-mail wijzigen'
        },
        change_password: {
          page_title      : 'Wachtwoord wijzigen',
          current_password: 'Huidig Wachtwoord',
          new_password    : 'Nieuw Wachtwoord',
          repeat_password : 'Herhaal nieuw wachtwoord',
          save_btn        : 'Opslaan',
          cancel_btn      : 'Annuleren',
          hints           : {
            current_password   : 'Vul hier uw huidige wachtwoord in',
            new_password       : 'Dit dient 8 karakters te zijn, 1 hoofdletter, kleine letter, cijfer en een speciaal teken.',
            new_password_repeat: 'Herhaal uw nieuw wachtwoord'
          }
        },
        change_email   : {
          page_title  : 'Account e-mailadres wijzigen',
          new_email   : 'Nieuwe account e-mailadres',
          repeat_email: 'Herhaal nieuw account e-mailadres',
          save_btn    : 'Opslaan',
          cancel_btn  : 'Annuleren',
          update_correspondence_email: 'Dit e-mailadres is ook mijn correspondentie e-mailadres.',
          hints       : {
            new_email       : 'Uw account e-mailadres is waarmee u kunt inloggen op mijnWML. Let op: Dit is niet uw correspondentieadres.',
            new_email_repeat: ''
          }
        }
      },
      msa                  : {
        choose_meter       : {
          title : {
            create: 'Afspraak meterwisseling maken',
            update: 'Afspraak {{issue}} wijzigen',
            delete: 'Afspraak {{issue}} annuleren'
          },
          email_address_confirmation: 'E-mailadres bevestiging',
          water_meter_selection     : 'Welke watermeter wilt u?',
          next                      : 'Volgende',
          cancel                    : 'Annuleren',
          meter_default             : 'Standaard',
          meter_large               : 'Groot',
          hints                     : {
            information_view_meter_default: 'Dit is ruim voldoende voor normaal huishoudelijk gebruik.',
            information_view_meter_large  : 'Deze meter is geschikt voor het leveren van een grotere capaciteit. Deze is bijvoorbeeld benodigd bij het gebruik van een brandslanghaspel.'
          }
        },
        summary            : {
          title: {
            create: 'Afspraak meterwisseling maken',
            update: 'Afspraak {{issue}} wijzigen',
            delete: 'Afspraak {{issue}} annuleren'
          },
          your_information          : 'Uw gegevens',
          edit_your_information     : 'Uw gegevens bewerken',
          connection_address        : 'Leveringsadres',
          email_address_confirmation: 'E-mailadres bevestiging',
          preferred_meter           : 'Gewenste watermeter',
          edit_preferred_meter      : 'Gewenste watermeter wijzigen',
          date_and_time             : 'Datum & tijd',
          edit_date_and_time        : 'Datum & tijd wijzigen',
          agree                     : 'Ik ga akkoord met bovenstaande gegevens',
          create_appointment        : 'Afspraak inplannen',
          update_appointment        : 'Afspraak wijzigen',
          delete_appointment        : 'Afspraak annuleren',
          cancel                    : 'Annuleren',
          meter_default             : 'Standaard',
          meter_large               : 'Groot',
          text_meter_default        : 'Dit is ruim voldoende voor normaal huishoudelijk gebruik.',
          text_meter_large          : 'Deze meter is geschikt voor het leveren van een grotere capaciteit. Deze is bijvoorbeeld benodigd bij het gebruik van een brandslanghaspel.'
        },
        confirmation       : {
          title: {
            create: 'Uw afspraak is gemaakt',
            update: 'Uw afspraak is gewijzigd',
            delete: 'Uw afspraak is geannuleerd'
          },
          page_title   : 'Uw afspraak is gemaakt',
          page_text    : 'U ontvangt binnen enkele minuten een bevestiging per e-mail.',
          page_text_addendum : 'U kunt de gemaakte afspraken nog tot 24 uur van te voren wijzigen.',
          btn_home     : 'Naar de homepagina',
          btn_dashboard: 'Naar het overzicht'
        },
        time_table         : {
          title : {
            create: 'Afspraak meterwisseling maken',
            update: 'Afspraak {{issue}} wijzigen',
            delete: 'Afspraak {{issue}} annuleren'
          },
          loading_choices: 'Keuze mogelijkheden laden',
          next_btn       : 'Volgende',
          cancel_btn     : 'Annuleren'
        },
        service_number_form: {
          title                     : 'Meter verwisselen',
          service_number            : 'Servicemeldingnummer',
          postal_code               : 'Postcode',
          placeholder_postal_code   : '0000 AB',
          next                      : 'Volgende'
        }
      },
      premise_register     : {
        page_title                       : 'Woning aanmelden',
        street                           : 'Straat',
        postal_code                      : 'Postcode',
        postal_code_placeholder          : '0000 AB',
        houseno_and_addtion              : 'Huisnummer & toevoeging',
        house_number_placeholder         : '12',
        house_number_addition_placeholder: 'A',
        city                             : 'Woonplaats',
        customernumber                   : 'Klantnummer',
        customernumber_placeholder       : '12345678',
        cancel                           : 'Annuleren',
        previous                         : 'Vorige',
        save                             : 'Volgende',
        hints                            : {
          street      : '',
          postal_code : 'De postcode van het adres dat u wilt aanmelden.',
          house_number: 'Het huisnummer met eventuele toevoeging van het adres dat u wilt aanmelden.',
          city        : ''
        },
        login: {
          hints: {
            postal_code: 'Uw postcode van het adres waarmee u klant wilt worden',
            house_number: 'Het huisnummer met eventuele toevoeging van het adres waarmee u klant wilt worden',
            customer_number: 'Klantnummer'
          }
        },
        meter_reading                    : {
          page_title       : 'Woning aanmelden',
          date_key_transfer: 'Datum sleuteloverdracht',
          meter_reading    : 'Meterstand',
          meter_number  : 'Meternummer',
          file_upload_description   : 'Afbeeldingen moeten kleiner zijn dan 20 MB. Toegestane bestandstypen gif, jpg, png.​',
          file_upload               : 'Foto van de watermeter toevoegen',
          file_upload_btn           : 'Bladeren',
          cancel           : 'Vorige',
          save             : 'Volgende',
          hints            : {
            meter_reading: '',
            meter_number : 'Het meternummer vindt u op de watermeter en begint met 003 of 005, VIK of VI.'
          }
        },
        new_premises: {
          text1   : 'De woning die u wilt aanmelden wordt ook uw nieuw correspondentieadres.',
          text2   : 'Indien u een ander correspondentieadres wilt opgeven kunt u dit hieronder doorgeven.',
          current_correspondence: 'Huidige correspondentieadres',
          different_correspondence_address: 'Ik heb een afwijkend correspondentieadres',
          cancel  : 'Vorige',
          save    : 'Volgende'
        },
        correspondence            : {
          page_title                        : 'Woning aanmelden',
          new_correspondence                : 'Nieuw correspondentieadres',
          current_correspondence            : 'Huidige correspondentieadres',
          other                             : 'Nieuw adres toevoegen',
          country                           : 'Land',
          select_a_country                  : 'Selecteer een land',
          option_NL                         : 'Nederland',
          option_BE                         : 'Belgie',
          option_DE                         : 'Duitsland',
          houseno_postal_code               : 'Postcode & Huisnummer',
          houseno_po_box                    : 'Postcode & Postbus',
          postal_code                       : 'Postcode',
          postal_code_placeholder           : '0000 AB',
          postal_code_placeholder_be        : '0000',
          postal_code_placeholder_de        : '00000',
          houseno_and_addtion               : 'Huisnummer & toevoeging',
          street                            : 'Straat',
          city                              : 'Plaats',
          po_box                            : 'Postbus',
          cancel                            : 'Vorige',
          save                              : 'Volgende',
          hints                             : {
            select_a_country   : '',
            postal_code        : '',
            houseno_and_addtion: '',
            street             : 'Dit wordt automatisch gevuld als u de postcode en huisnummer ingevuld heeft',
            city               : 'De plaats wordt automatisch gevuld na invullen postcode en huisnummer',
            po_box             : ''
          }
        },
        futuremail: {
          correspondence_email: 'Correspondentie e-mailadres',
          update_account_email: 'Dit e-mailadres is ook mijn mijnWML account e-mailadres.',
          sendcontrol_label: 'Factuur digitaal ontvangen?',
          email_post: {
            yes: 'Ja',
            no: 'Nee'
          },
          hints: {
            correspondence_email: 'Via dit e-mailadres ontvangt u alle post van WML',
            sendcontrol_label: 'sendcontrol_label'
          }
        },
        summary                          : {
          page_title        : 'Woning aanmelden',
          date              : 'Datum',
          email_post: {
            label: 'Post ontvangen via E-mail',
            yes: 'Ja',
            no: 'Nee'
          },
          email             : 'Persoonsgegevens',
          email_address_label: 'Correspondentie<br> e-mailadres',
          update_account_email : 'Dit e-mailadres is ook mijn mijnWML account e-mailadres.',
          meter_reading     : 'Meterstand',
          meter_reading_edit: 'Meterstand wijzigen',
          move_in_date_edit : 'Datum sleuteloverdracht wijzigen',
          future_connection_address : 'Toekomstige leveringsadres',
          future_address_edit      : 'Toekomstige leveringsadres wijzigen',
          address           : 'Adres',
          meter_number      : 'Meternummer',
          connection_address: 'Leveringsadres',
          address_edit      : 'Leveringsadres wijzigen',
          correspondence_address_edit: 'Correspondentieadres wijzigen',
          new_correspondence_address: 'Nieuw Correspondentieadres',
          future: {
            change_correspondence: 'Correspondentiegegevens wijzigen',
            correspondence: 'Correspondentiegegevens',
            email: 'E-mailadres',
            update_account_email: 'Dit e-mailadres is ook mijn mijnWML account e-mailadres.',
            digital: 'Digitaal post ontvangen?'
          },
          cancel            : 'Vorige',
          save              : 'Bevestigen'
        },
        thank_you                        : {
          page_title: 'De woning is aangemeld voor drinkwaterlevering',
          page_future_title: 'Bedankt voor uw toekomstige aanmelding bij WML.',
          text      : 'De woning is aangemeld voor drinkwaterlevering. U ontvangt hiervan een bevestiging per e-mail. Wij gaan uw gegevens verwerken in onze administratie en binnen twee weken ontvangt u bericht.',
          future_text: 'U ontvangt op {{date}} een herinnering per e-mail. Hierin vragen wij u de watermeterstand door te geven. Wij maken dan uw aanmelding compleet.',
          btn       : 'Naar het overzicht'
        }
      },
      premise_unregister   : {
        page_title                : 'Woning afmelden',
        premises_select           : 'Selecteer het leveringsadres dat u wilt afmelden',
        premises_unregistered     : 'Reeds afgemelde leveringsadressen',
        premises_unregistered_desc: 'Kloppen de gegevens niet, neem dan contact op met de  <a href=\"/contact\" title=\"Klantenservice\">klantenservice</a>. Controleer je huidig  <a href=\"/mijn-persoonlijke-gegevens\" title=\"correspondentieadres\">correspondentieadres</a> en  <a href=\"/mijn-persoonlijke-gegevens\" title=\"e-mailadres\">e-mailadres</a>.',
        none_selectable           : 'Er zijn geen leveringsadressen die u kunt afmelden via het mijnWML portaal.',
        not_selectable            : 'Leveringsadressen die u niet kunt afmelden via mijnWML',
        not_selectable_text       : 'Wilt u één van de onderstaande leveringsadressen afmelden, neem dan <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met de klantenservice.',
        not_selectable_text_single: 'Wilt u onderstaand leveringsadres afmelden, neem dan <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met de klantenservice.',
        no_premises               : 'Er zijn geen woningen ',
        previous                  : 'Vorige',
        cancel                    : 'Annuleren',
        save                      : 'Volgende',
        customernumber                   : 'Klantnummer',
        customernumber_placeholder       : '12345678',
        postal_code                      : 'Postcode',
        postal_code_placeholder          : '0000 AB',
        house_number                     : 'Huisnummer',
        house_number_placeholder         : '12',
        house_number_addition            : 'toevoeging',
        house_number_addition_placeholder: 'A',
        btn_next                  : 'Volgende',
        unregister_addresses_table: {
          address: 'Adres',
          meter_number: 'Meter<wbr>nr.',
          date: 'Datum',
          meter_reading: 'Meter<wbr>stand'
        },
        meter_reading             : {
          page_title       : 'Woning afmelden',
          date_key_transfer: 'Datum sleuteloverdracht',
          meter_reading    : 'Meterstand',
          meter_number     : 'Meternummer',
          file_upload_description   : 'Afbeeldingen moeten kleiner zijn dan 20 MB. Toegestane bestandstypen gif, jpg, png.​',
          file_upload               : 'Foto van de watermeter toevoegen',
          file_upload_btn           : 'Bladeren',
          cancel           : 'Vorige',
          save             : 'Volgende',
          hints            : {
            meter_reading: '',
            meter_number : 'Het meternummer vindt u op de watermeter en begint met 003 of 005, VIK of VI.'
          }
        },
        login: {
          hints: {
            customer_number: 'Klantnummer hint',
            postal_code: 'De postcode van het adres dat u wilt afmelden.',
            house_number: 'Het huisnummer met eventuele toevoeging van het adres dat u wilt afmelden.'
          }
        },
        correspondence            : {
          page_title                        : 'Woning afmelden',
          text                              : 'De woning die u wilt afmelden is ook uw huidige correspondentieadres. Geef een nieuw correspondentieadres door.',
          new_correspondence                : 'Nieuw correspondentieadres',
          other                             : 'Nieuw adres toevoegen',
          country                           : 'Land',
          select_a_country                  : 'Selecteer een land',
          option_NL                         : 'Nederland',
          option_BE                         : 'Belgie',
          option_DE                         : 'Duitsland',
          houseno_postal_code               : 'Postcode & Huisnummer',
          houseno_po_box                    : 'Postcode & Postbus',
          postal_code                       : 'Postcode',
          postal_code_placeholder           : '0000 AB',
          postal_code_placeholder_be        : '0000',
          postal_code_placeholder_de        : '00000',
          houseno_and_addtion               : 'Huisnummer & toevoeging',
          street                            : 'Straat',
          city                              : 'Plaats',
          po_box                            : 'Postbus',
          cancel                            : 'Vorige',
          save                              : 'Volgende',
          hints                             : {
            select_a_country   : '',
            postal_code        : '',
            houseno_and_addtion: '',
            street             : 'Dit wordt automatisch gevuld als u de postcode en huisnummer ingevuld heeft',
            city               : 'De plaats wordt automatisch gevuld na invullen postcode en huisnummer',
            po_box             : ''
          }
        },
        email: {
          page_title: 'Woning afmelden',
          cancel: 'Vorige',
          hints: {
            email: 'Uw e-mailadres waarmee wij contact met u op kunnen nemen'
          },
          email_request: {
            text: 'Wilt u de eindafrekening per e-mail ontvangen dan verzoeken wij u hier uw e-mailadres in te vullen:',
            label_short: 'E-mailadres',
            label: 'E-mailadres voor bevestiging',
            placeholder: 'E-mailadres'
          },
          email_post: {
            label: 'Mogen wij dit e-mailadres ook gebruiken om in de toekomst uw post digitaal te versturen?',
            yes: 'Ja',
            no: 'Nee'
          }
        },
        futuremail: {
          correspondence_email: 'Correspondentie e-mailadres',
          update_account_email: 'Dit e-mailadres is ook mijn mijnWML account e-mailadres.',
          sendcontrol_label: 'Factuur digitaal ontvangen?',
          email_post: {
            yes: 'Ja',
            no: 'Nee'
          },
          hints: {
            correspondence_email: 'Via dit e-mailadres ontvangt u alle post van WML',
            sendcontrol_label: 'sendcontrol_label'
          }
        },
        summary                   : {
          page_title        : 'Woning afmelden',
          date              : 'Datum',
          meter_reading     : 'Meterstand',
          meter_reading_edit: 'Meterstand wijzigen',
          email             : 'Persoonsgegevens',
          email_address_label: 'Correspondentie<br> e-mailadres',
          email_label       : 'E-mailadres voor bevestiging',
          email_edit        : 'E-mailadres wijzigen',
          email_post        : 'Post ontvangen via E-mail',
          address           : 'Adres',
          meter_number      : 'Meternummer',
          connection_address: 'Nieuw Correspondentieadres',
          current_connection_address: 'Correspondentieadres',
          address_edit      : 'Correspondentieadres wijzigen',
          po_box            : 'Postbus',
          future: {
            change_correspondence: 'Correspondentiegegevens wijzigen',
            correspondence: 'Correspondentiegegevens',
            email: 'E-mailadres',
            update_account_email: 'Dit e-mailadres is ook mijn mijnWML account e-mailadres.',
            digital: 'Digitaal post ontvangen?'
          },
          save              : 'Bevestigen',
          done              : 'Afronden',
          update_account_email : 'Dit e-mailadres is ook mijn mijnWML account e-mailadres.',
          already_unregistered_desc: 'De woning op onderstaand leveringsadres is al afgemeld. Kloppen de gegevens niet? Wijzig dit meteen of neem <a href=\"/contact\" title=\"Klantenservice\">contact</a> op met onze klantenservice.'
        },
        thank_you                 : {
          page_title: 'De woning is afgemeld voor drinkwaterlevering',
          text      : 'De woning is afgemeld voor drinkwaterlevering. U ontvangt hiervan een bevestiging per e-mail. Wij gaan uw gegevens verwerken in onze administratie en binnen twee weken ontvangt u de eindafrekening.',
          page_future_title: 'Bedankt voor uw toekomstige afmelding bij WML.',
          future_text: 'U ontvangt op {{date}} een herinnering per e-mail. Hierin vragen wij u de watermeterstand door te geven. U ontvangt dan uw eindafrekening.',
          btn       : 'Naar mijn overzicht',
          btn_home  : 'Naar de Home pagina'
        }
      },
      premises             : {
        action_overview : {
          premise_unregister_title     : 'Ik wil mijn woning afmelden voor drinkwaterlevering',
          premise_unregister_paragraph : 'Ga verder als u uw woning wilt afmelden voor drinkwaterlevering.',
          premise_unregister_paragraph2: 'Gaat u verhuizen en wilt u de oude woning afmelden? Dit gaat alleen als de sleuteloverdracht al is geweest.',
          premise_unregister_btn       : 'Ga verder',
          premise_register_title       : 'Ik wil mijn woning aanmelden voor drinkwaterlevering',
          premise_register_paragraph   : 'Ga verder als u uw woning wilt aanmelden voor drinkwaterlevering.',
          premise_register_paragraph2  : 'Gaat u verhuizen en wilt u de nieuwe woning aanmelden? Dit gaat alleen als de sleuteloverdracht al is geweest.',
          premise_register_btn         : 'Ga verder'
        },
        askContinueModal: {
          moving_in : {
            title     : 'De woning is aangemeld voor drinkwaterlevering.',
            paragraph : 'U ontvangt hiervan een bevestiging per e-mail',
            paragraph2: 'Gaat u verhuizen en wilt u de oude woning afmelden? Dit gaat alleen als de sleuteloverdracht al is geweest.',
            yes       : 'Ja',
            no        : 'Nee'
          },
          moving_out: {
            title     : 'De woning is afgemeld voor drinkwaterlevering.',
            paragraph : 'U ontvangt hiervan een bevestiging per e-mail',
            paragraph2: 'Gaat u verhuizen naar een nieuwe woning in Limburg en wilt u deze aanmelden? Dit gaat alleen als de sleuteloverdracht al is geweest.'
          }
        }
      },
      vacancy_page         : {
        titles: {
          description       : 'Beschrijving',
          core_activities   : 'Kernactiviteiten',
          edu_work_exp      : 'Opleiding & werkervaring',
          skills            : 'Vaardigheden & competenties',
          competences       : 'Competenties',
          other_info        : 'Overige informatie',
          content_info      : 'Inhoudelijke informatie',
          working_conditions: 'Arbeidsvoorwaardelijke informatie',
          procedure         : 'Interesse?'
        }
      },
      vacancyForm          : {
        apply_to_vacancy_text         : 'Wil je reageren op deze vacature? Vul dan onderstaand formulier in.',
        vacancy                       : 'Vacature',
        gender                        : 'Geslacht',
        male                          : 'M',
        female                        : 'V',
        name                          : 'Naam',
        street                        : 'Straatnaam',
        house_no_and_addition         : 'Huisnummer & toevoeging',
        house_no_placeholder          : '12',
        house_no_addition_placeholder : 'A',
        postal_code                   : 'Postcode',
        postal_code_placeholder       : '0000 AB',
        postal_code_placeholder_BE    : '0000',
        postal_code_placeholder_DE    : '00000',
        city                          : 'Woonplaats',
        country                       : 'Land',
        email                         : 'E-mailadres',
        phone                         : 'Telefoonnummer',
        vacancy_found_at              : 'Vacature gevonden via',
        conditions_accepted           : 'Ik heb de privacyverklaring sollicitanten gelezen en mhet versturen van mijn sollicitatiebrief en mijn curriculum vitae geef ik WML toestemming mijn persoonsgegevens te verwerken en op te nemen in de de database van WML. Ingeval ik WML ongevraagde en aanvullende persoonsgegevens verstrek, geef ik WML hierbij toestemming ook deze persoonsgegevens te verwerken.',
        conditions_text               : 'Je staat op het punt een sollicitatie in te dienen bij WML. WML heeft een privacyverklaring voor sollicitanten opgesteld. Hierin kun je lezen welke persoonsgegevens WML van jou verwerkt, met welke doelen en op welke wijze. Hieronder vragen wij toestemming om jouw sollicitatiebrief en curriculum vitae ten behoeve van de sollicitatie te verwerken. De privacyverklaring sollicitanten kun je <a href=\"/assets/privacyverklaring_sollicitanten.pdf\" target=\"_blank\">hier</a> downloaden.',
        conditions_revoke             : 'Je gegeven toestemming kun je altijd weer intrekken. Dit heeft geen terugwerkende kracht, de eerder onder de toestemming verrichte verwerkingen door WML blijven rechtsgeldig. WML zal na jouw intrekking de verwerking staken. Dit betekent dat de sollicitatieprocedure geen doorgang vindt. Stuur voor het intrekken van je toestemming een mail naar <a href=\"mailto:vacature@wml.nl?subject=Intrekken toestemming verwerken persoonsgegevens sollicitant\">vacature@wml.nl</a> en vermeld daarin je naam, om welke vacature het gaat en dat je de toestemming intrekt.',
        add_motivation_btn            : 'Motivatiebrief toevoegen',
        add_cv_btn                    : 'CV toevoegen',
        send_application_btn          : 'Verstuur sollicitatie',
        validation_error              : {
          max_size: 'Het is helaas bestand te groot. De maximale upload grote is 6MB.'
        }
      },
      detail_page          : {
        bottom_block_title : 'Regel al uw zaken zelf via mijnWML',
        bottom_block_text  : 'Donec vitae lobortis sapien, vel mattis justo. Quisque auctor eu ligula non vestibulum. Donec nisl nunc, commodo elementum lacus ac, scelerisque venenatis lectus. Maecenas elementum eu enim eget ultrices. Donec vitae lorem vulputate',
        bottom_block_button: 'Ga naar mijnWML',
        view_video         : 'Bekijk video',
        user_responses     : {
          title       : 'Reacties',
          form_title  : 'Reageren',
          form_name   : 'Naam',
          form_email  : 'E-mail',
          form_message: 'Bericht',
          form_send   : 'Verstuur'
        },
        read_more          : 'lees meer'
      },
      list_page            : {
        list_item_read_more: 'lees meer',
        page_counter       : '<strong>{{range}}</strong> van <strong>{{total}}</strong> items'
      },
      videoList: {
        btnDefaultTxt: 'Klik hier'
      },
      faq                  : {
        title           : 'Vraag en antwoord',
        other_question  : 'Heeft u een andere vraag?',
        text            : 'Bekijk de <a href=\"/veel-gestelde-vragen\">veelgestelde vragen</a> over andere onderwerpen of <a href=\"/contact\" title=\"Klantenservice\">stel ons een vraag</a>.',
        top_items       : 'Top {{length}} veelgestelde vragen',
        categories_title: 'Veelgestelde vragen per categorie',
        view_video      : 'Bekijk video'
      },
      breadcrumb           : {
        home             : 'Home',
        profile          : 'Mijn account',
        dashboard        : 'Mijn overzicht',
        customer         : 'Mijn persoonlijke gegevens',
        invoicesDashboard: 'Mijn financiële gegevens',
        usageDashboard   : 'Mijn verbruik'
      },
      standpipe            : {
        form          : {
          title                       : 'Standpijp aanvragen',
          company_name                : 'Bedrijfsnaam',
          client_name                 : 'Naam aanvrager',
          invoice_address             : 'Factuuradres',
          street                      : 'Straat / postbus',
          house_no_and_addition       : 'Huisnummer & toevoeging',
          postal_code                 : 'Postcode',
          city                        : 'Plaats',
          phone                       : 'Telefoonnummer',
          email                       : 'E-mail',
          invoice_electronic          : 'Ik wil mijn factuur via e-mail ontvangen',
          standpipe_location          : 'Locatie standpijp',
          location_contact_person     : 'Contactpersoon',
          location_phone              : 'Telefoonnummer',
          location_street             : 'Straat',
          location_house_no           : 'Huisnummer & toevoeging (dichtsbijzijnd)',
          location_postal_code        : 'Postcode',
          location_city               : 'Plaats',
          location_hydrant            : 'Brandkraannummer (indien bekend)',
          location_message            : 'Aanvullende informatie',
          location_message_irrigation : 'Aanvullende informatie (voor locaties)',
          date_time                   : 'Datum en tijdstip',
          date_time_text              : 'Uw aanvraag dient minimaal 5 werkdagen voor de gewenste plaatsingsdatum verstuurd te worden. Voor spoedaanvragen wordt € 76,- toeslag berekend.',
          date_time_text_irrigation   : 'Uw aanvraag dient minimaal 5 werkdagen voor de gewenste plaatsingsdatum verstuurd te worden. Na de initiële plaatsing van de standpijp door een monteur van WML, mag u zelf de standpijp plaatsen op de daartoe aangewezen brandkranen. Hiertoe dient u wel de gebruiksinstructies nauwlettend op te volgen.',
          date_start                  : 'Datum plaatsing (alleen werkdagen)',
          time_start                  : 'Tijdstip',
          date_end                    : 'Datum verwijdering (alleen werkdagen)',
          time_end                    : 'Tijdstip',
          text_presence_required      : 'Bij de plaatsing en verwijdering is aanwezigheid vereist. Als u niet aanwezig bent, wordt de standpijp niet geplaatst of verwijderd en worden voorrijkosten in rekening gebracht.',
          capacity                    : 'Capaciteit',
          capacity_standpipe          : 'Capaciteit standpijp',
          capacity_text_paragraph1    : 'Een standpijp met een capaciteit van 4 m3/uur is vergelijkbaar met een huisaansluiting. Een standpijp beschikt over twee tapkranen met een uitloopkoppeling van ¾”, waarop 2 slangen (type ‘tuinslang’) aangesloten kunnen worden. Sommige standpijpen beschikken over een ¾” Geka koppeling.',
          capacity_text_paragraph2    : '* Heeft u een hogere capaciteit dan 4 m3/uur geselecteerd, dan neemt onze afdeling Klantenservice telefonisch contact met u op. WML beoordeelt dan of de gewenste grotere capaciteit mogelijk is op de gewenste locatie en of deze bij WML voorradig is. Voor een grotere capaciteit betaalt u een hoger huurbedrag.',
          capacity_text_irrigation    : 'De beregeningsstandpijp is voorzien van een brandslang van 10 meter welke dient om uw buffer-bassin te vullen. Het is niet mogelijk uw beregeningsinstallatie rechtstreeks aan te sluiten op de standpijp.',
          treatment                   : 'Bent u voornemens om de standpijp aan te sluiten op een apparaat/installatie, stoffen toe te voegen aan het water of het te behandelen, alvorens u deze gebruikt?',
          treatment_option_yes        : 'Ja',
          treatment_option_no         : 'Nee',
          quality                     : 'Drinkwaterkwaliteit',
          quality_warn                : 'Leidingwater onttrokkken via een standpijp is geen officieel drinkwater. Voor gebruik als drinkwater geldt een kookadvies van 3 minuten. WML kan u helpen om uw evenement toch van bacteriologisch betrouwbaar water te voorzien. Onze afdeling Verkoop geeft u graag informatie. Telefoonnummer 043 880 8558.',
          quality_warn_irrigation     : 'Leidingwater onttrokken via een standpijp is geen officieel drinkwater. Voor gebruik als drinkwater geldt een kookadvies van 3 minuten.',
          consumption                 : 'Bent u voornemens het water uit de standpijp te gebruiken voor consumptieve doeleinden (drinkwater)?',
          consumption_option_yes      : 'Ja',
          consumption_option_no       : 'Nee',
          rates                       : 'Tarieven',
          terms_rent                  : 'Ik ga akkoord met de huurtarieven van WML',
          rates_irrigation            : 'De tarieven voor de beregeningsstandpijp zijn op aanvraag. We zullen hiervoor telefonisch contact met u opnemen.',
          terms                       : 'Voorwaarden',
          accept_conditions           : 'Ik ga akkoord met de <a href=\"/footer/huurvoorwaarden-standpijpen\" title=\"Huurvoorwaarden Standpijpen\">Huurvoorwaarden Standpijpen</a>, de <a href=\"/footer/algemene-voorwaarden\" title=\"Algemene Voorwaarden Drinkwater\">Algemene Voorwaarden Drinkwater</a> en de <a href=\"/footer/aanvullende-voorwaarden-zakelijke-diensten-wml-nv\" title=\"Aanvullende Voorwaarden Zakelijke Diensten WML NV\">Aanvullende Voorwaarden Zakelijke Diensten WML NV</a>.',
          accept_conditions_irrigation: 'Ik ga akkoord met de <a href=\"/footer/algemene-voorwaarden\" title=\"Algemene Voorwaarden Drinkwater\">Algemene Voorwaarden Drinkwater</a>, de <a href=\"/footer/aanvullende-voorwaarden-zakelijke-diensten-wml-nv\" title=\"Aanvullende Voorwaarden Zakelijke Diensten WML NV\">Aanvullende Voorwaarden Zakelijke Diensten WML NV</a> en de <a href=\"/footer/huurvoorwaarden-beregeningsstandpijpen\" title=\"Huurvoorwaarden Beregeningsstandpijpen\">Huurvoorwaarden Beregeningsstandpijpen</a>.',
          customer_research           : 'Klantonderzoek',
          customer_research_email     : 'WML mag mij periodiek via e-mail voorzien van informatie of vragen deel te nemen aan een klantonderzoek',
          send                        : 'Verstuur aanvraag',
          hints                       : {
            date_end           : 'Vul bij jaarhuur de datum van volgend jaar in bij verwijdering standpijp.',
            date_end_irrigation: 'Na ontvangst van dit formulier nemen wij telefonisch contact met u op om de exacte plaatsingslocatie(s) van de beregeningsstandpijp met u af te stemmen.',
            location_message   : 'Na ontvangst van dit formulier nemen wij telefonisch contact met u op om de exacte plaatsingslocatie(s) van de beregeningsstandpijp met u af te stemmen.'
          }
        },
        start_timeslot: {
          '0800-1200': '08.00 - 12.00 uur',
          '1230-1630': '12.30 - 16.30 uur'
        },
        end_timeslot  : {
          '0800-1200': '08.00 - 12.00 uur',
          '1230-1630': '12.30 - 16.30 uur',
          '1630-2400': '16.30 - 24.00 uur'
        },
        capacity      : {
          hour4 : '4 m3/uur',
          hour6 : '6 m3/uur',
          hour10: '10 m3/uur* (geen jaarhuur mogelijk)',
          hour16: '16 m3/uur* (geen jaarhuur mogelijk)',
          hour35: '35 m3 / uur'
        }
      },
      waterhardheid        : {
        postal_code            : 'Postcode',
        postal_code_placeholder: '0000 AB',
        house_no               : 'Huisnummer',
        house_no_placeholder   : '1',
        submit                 : 'Controleer uw hardheid'
      },
      damage_form          : {
        title                       : 'Schadeformulier',
        information_legend          : 'Persoons- en Adresgegevens',
        customer_number             : 'Klantnummer',
        name                        : 'Naam',
        initials                    : 'Voorletters',
        street                      : 'Straat',
        house_no                    : 'Huisnummer & toevoeging',
        postal_code                 : 'postcode',
        postal_code_placeholder     : '0000 AB',
        city                        : 'Plaats',
        phone_number                : 'Telefoonnummer',
        email                       : 'E-mail',
        iban                        : 'IBAN',
        damage_legend               : 'Schade',
        date                        : 'Datum',
        time                        : 'Tijdstip',
        location                    : 'Waar',
        about                       : 'Betreft',
        add_img_btn                 : 'Bijlagen m.b.t de schade toevoegen',
        upload_limit                : 'Het uploadlimiet is overschreden. U kunt maximaal <span>{{uploadLimit}}</span> aan afbeeldingen uploaden.',
        damage_report_label         : 'Heeft u de schade al gemeld bij uw eigen verzekering',
        damage_report_true          : 'Ja',
        damage_report_false         : 'Nee',
        insurance_agency            : 'Bij welke verzekeringsmaatschappij?',
        polis                       : 'Polisnummer',
        damage_cause                : 'Feitelijke omschrijving oorzaak schade',
        item_description            : 'Omschrijving',
        item_purchase_date          : 'Aankoopdatum',
        item_purchase_price         : 'Aanschafprijs',
        item_day_value              : 'Dagwaarde',
        item_estimated_damage       : 'Schatting schade c.q. herstelkosten',
        add_item                    : 'Voeg een nieuwe regel toe',
        declaration                 : 'Verklaring',
        send_form                   : 'Verstuur'
      },
      search               : {
        title      : 'Zoekresultaat',
        no_result  : 'Er is helaas geen resultaat gevonden.',
        search     : 'Zoeken',
        what       : 'Waar bent u naar op zoek?',
        btn        : 'Zoeken',
        placeholder: 'Doorzoek de website',
        read_more: 'Lees meer',
        no_suggestions: 'Geen suggesties',
        one_result: '1 resultaat gevonden',
        many_results: '<strong>%START%</strong> - <strong>%END%</strong> van <strong>%TOTAL%</strong> items',
        few_results: '%TOTAL% resultaten gevonden',
        no_results_for_query: 'Geen resultaten gevonden voor %QUERY%',
        previous: 'Vorige',
        next: 'Volgende'
      },
      corporation  : {
        title                 : 'Leveringsadres kiezen',
        text                  : 'Kies het leveringsadres waarvan u de gegevens wilt inzien.',
        search                : 'Zoeken',
        select_btn            : 'Selecteer adres',
        filter_active         : 'Actieve leveringsadressen',
        filter_inActive       : 'Inactieve leveringsadressen',
        contract_active_title : 'Actief:',
        contract_address_title: 'Leveringsadres:',
        active_from           : 'Van:',
        active_to             : 'Tot:',
        active_now            : 'heden',
        contract_info_title   : 'Contract:',
        contract_id_no        : 'Contractnummer:',
        contract_meter_no     : 'Meternummer:',
        contract_install_no   : 'Perceelnummer:'
      },
      carafe               : {
        form: {
          legend_company_info              : 'Bedrijfsgegevens',
          kvk_number                       : 'KvK-nummer',
          company_name                     : 'Bedrijfsnaam',
          contact_person_name              : 'Naam contactpersoon',
          contact_person_phone             : 'Telefoonnummer contactpersoon',
          contact_person_email             : 'E-mailadres contactpersoon',
          legend_address                   : 'Adres',
          company_street                   : 'Straat',
          postal_code                      : 'Postcode',
          company_house_number             : 'Huisnummer',
          company_city                     : 'Plaats',
          placeholder_housenumber          : '12',
          placeholder_housenumber_additions: 'A',
          placeholder_postal_code          : '0000 AB',
          legend_order                     : 'Bestelling',
          type_order                       : 'Type bestelling',
          choose_type                      : 'Kies een type',
          choose_type_option_first_set     : 'Eerste gratis 12 karaffen',
          choose_type_option_extra         : 'Extra karaffen tegen inkoopprijs - &euro;42.50 excl. BTW per 6 karaffen extra',
          order_amount                     : 'Extra karaffensets',
          questions                        : 'Eventuele vragen of opmerkingen',
          btn_send                         : 'Karaffen aanvragen',
          accept_conditions                : 'Ik ga akkoord met de <a href=\"/footer/voorwaarden-karaffen\" title=\"Verkoopvoorwaarden Karaffen\">voorwaarden</a> en tarieven voor extra karaffen'
        }
      },
      waterEstafette        : {
        form: {
          association: {
            title                          : 'Gegevens vereniging',
            name                           : 'Naam vereniging',
            type                           : 'Soort vereniging (bijv. voetbalvereniging)',
            postal_code                    : 'Postcode vereniging',
            placeholder_postal_code        : '0000 AB',
            city                           : 'Woonplaats vereniging'
          },
          contact    : {
            title: 'Gegevens contactpersoon',
            name : 'Naam contactpersoon vereniging',
            email: 'E-mailadres',
            phone: 'Mobiel nummer'
          },
          competitors: {
            title                  : 'Inschrijving (max. 15 personen)',
            number_of_persons_error: 'Let op: Het maximaal deelnemers per vereniging is 15. <br>U dient uw inschrijving aan te passen voordat u deze kunt verzenden.',
            type                   : 'Discipline',
            count                  : 'Aantal',
            distance               : 'Afstand',
            add_competitor         : 'Deelnemer(s) toevoegen'

          },
          btn_send   : 'Inschrijven'
        },
        vote_form : {
          title : 'Stemmen',
          city : 'Kies de locatie van de vereniging waarop u wilt stemmen',
          registrations : 'Verenigingen',
          already_voted : 'Uw stem is verwerkt, Bedankt voor uw stem!',
          btn_send : 'Stemmen'
        }
      },
      social: {
        share_page: "Deel deze pagina:"
      },
      overlay: {
        unavailable: {
          title: 'Website momenteel te druk',
          text: 'Op dit moment is de WML website niet goed bereikbaar.<br />Probeer het over een aantal minuten a.u.b. nogmaals.',
          link: 'Kijk op twitter voor de actuele informatie.'
        }
      },
      password: {
        criteria: {
          title: 'Uw wachtwoord moet voldoen aan:',
          min_length_single: 'Minimale lengte van 1 karakter',
          min_length_plural: 'Minimale lengte van {{value}} karakters',
          min_caps_single: 'Minimaal 1 hoofdletter',
          min_caps_plural: 'Minimaal {{value}} hoofdletters',
          min_lowercase_single: 'Minimaal 1 letter',
          min_lowercase_plural: 'Minimaal {{value}} letters',
          min_numbers_single: 'Minimaal 1 cijfer',
          min_numbers_plural: 'Minimaal {{value}} cijfers',
          min_special_chars_single: 'Minimaal 1 speciaal karakter',
          min_special_chars_plural: 'Minimaal {{value}} speciale karakters'
        }
      }
    });
})();
