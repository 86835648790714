(function() {
  'use strict';

  /** @ngInject */
  function CustomerDashboardController(customerService, $window, $state, $translate, $rootScope, enumService, contactPersonService, billingInformationService, premisesService, registry, storage, moment) {

    var vm  = this;

    // private variables
    vm.monthTranslations = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

    // Public variables
    vm.data                       = {};
    vm.insertions                 = null;
    vm.connectionAddresses        = null;
    vm.editDonation               = false;
    vm.changedWflAmount           = 0;

    // contacyPersons settings
    vm.contactPersonTypes         = ['general', 'administration', 'incidents', 'newsletter'];
    vm.contactPersonMaxPerType    = {general: 1, administration: 1, incidents: 3, newsletter: 3}; // type: record<contactPersonTypes, number>
    vm.contactPersonModePerType   = {general: false, administration: false, incidents: false, newsletter: false}; // TODO enum. VIEW EDIT ADD; type: record<contactPersonTypes, contactPersonMode[]>
    vm.contactPersonAvailability  = {general: false, administration: false, incidents: false, newsletter: false}; // at least one of type available; type: record<contactPersonTypes, boolean[]>
    vm.contactPersonData          = {general: false, administration: false, incidents: false, newsletter: false}; // the actual contact data; type: record<contactPersonTypes, contactPerson[]>
    vm.contactPersonDataCopy      = {general: [], administration: [], incidents: [], newsletter: []}; // a copy for editing; type: record<contactPersonTypes, contactPerson[]>
    vm.contactPersonSendingData   = {general: false, administration: false, incidents: false, newsletter: false}; // submit form booleans; type: record<contactPersonTypes, boolean[]>
    vm.contactPersonForms         = {general: false, administration: false, incidents: false, newsletter: false}; // forms; type: record<contactPersonTypes, form[]>

    // Public function definitions
    vm.editContactPerson  = editContactPerson;
    vm.downloadPremiseCsv = downloadPremiseCsv;
    vm.goToDatalogger     = goToDatalogger;
    vm.saveContactPerson   = saveContactPerson;
    vm.saveCustomerAccount = saveCustomerAccount;
    vm.cancelContactPerson = cancelContactPerson;
    vm.addContactPerson    = addContactPerson;
    vm.removeContactPerson = removeContactPerson;
    vm.removeContactPersonRow = removeContactPersonRow;
    vm.saveDonationChange = saveDonationChange;

    function saveDonationChange(stop) {
      vm.editDonation = false;
      if (stop) {
        vm.changedWflAmount = 0;
      }
      // only make call if there is a change
      if (vm.changedWflAmount !== vm.data.wfl_amount) {
        vm.data.email.update_account_email = false;
        vm.data.wfl_amount = vm.changedWflAmount;
        vm.data.wfl_month = moment().format('M');
        vm.month = vm.monthTranslations[vm.data.wfl_month - 1];
        vm.data.save().then();
      }
    }

    // Public functions
    function addContactPerson(contactPersonType) {
      // check if allowed (max)
      if (!vm.contactPersonData[contactPersonType] || (vm.contactPersonData[contactPersonType].length < vm.contactPersonMaxPerType[contactPersonType])) {
        if (!vm.contactPersonData[contactPersonType]) {
          vm.contactPersonAvailability[contactPersonType] = true;
          vm.contactPersonData[contactPersonType] = [];
          vm.contactPersonModePerType[contactPersonType] = [];
        }
        vm.contactPersonData[contactPersonType].push({});
        vm.contactPersonModePerType[contactPersonType][vm.contactPersonData[contactPersonType].length-1] = 'ADD';
      }
    }

    function editContactPerson(contactPersonType, account, rowIndex) {
      if(angular.isDefined(account) && angular.isObject(account)) {
        vm.contactPersonDataCopy[contactPersonType][rowIndex] = _.cloneDeep(vm.contactPersonData[contactPersonType][rowIndex]);
        vm.contactPersonModePerType[contactPersonType][rowIndex] = 'EDIT';
      }
    }

    function removeContactPerson(contactPersonType, account, rowIndex) {
      var params = { type: contactPersonType };
      if (angular.isDefined(account) && angular.isObject(account)) {
        params.account_id = account.customer_number || account.relation_account_id;
        contactPersonService.remove(params).then(function (data) {
          vm.removeContactPersonRow(contactPersonType, rowIndex);
          $rootScope.$broadcast('clear-cache', {contactperson : {contactperson: true}, customer : {customer: true}});
        }).catch(function () {
          vm.contactPersonModePerType[contactPersonType][rowIndex] = 'VIEW';
        });
      }
    }

    function removeContactPersonRow(contactPersonType, rowIndex) {
      vm.contactPersonData[contactPersonType].splice(rowIndex, 1);
      vm.contactPersonDataCopy[contactPersonType].splice(rowIndex, 1);
      vm.contactPersonModePerType[contactPersonType].splice(rowIndex, 1);
      if (!vm.contactPersonData[contactPersonType].length) {
        vm.contactPersonAvailability[contactPersonType] = false;
        vm.contactPersonData[contactPersonType] = false;
        vm.contactPersonModePerType[contactPersonType] = false;
      }
    }

    function cancelContactPerson(contactPersonType, rowIndex) {
      // edit, set back copy of data
      if (vm.contactPersonModePerType[contactPersonType][rowIndex] === 'EDIT') {
        vm.contactPersonData[contactPersonType][rowIndex] = vm.contactPersonDataCopy[contactPersonType][rowIndex];
        vm.contactPersonModePerType[contactPersonType][rowIndex] = 'VIEW';
      }
      // add, remove temp
      if (vm.contactPersonModePerType[contactPersonType][rowIndex] === 'ADD') {
        vm.removeContactPersonRow(contactPersonType, rowIndex);
      }
    }

    // save general admin contact
    function saveCustomerAccount(contactPersonType, rowIndex) {
      if (vm.contactPersonForms[contactPersonType][rowIndex].$invalid) {
        return;
      }
      if (!angular.isArray(vm.contactPersonSendingData[contactPersonType])) {
        vm.contactPersonSendingData[contactPersonType] = [];
      }
      vm.contactPersonSendingData[contactPersonType][rowIndex] = true;
      vm.data.email.update_account_email = false;
      vm.data.email = vm.contactPersonData[contactPersonType][rowIndex].email || '';
      vm.data.phone = vm.contactPersonData[contactPersonType][rowIndex].phone || '';
      vm.data.mobile_phone = vm.contactPersonData[contactPersonType][rowIndex].mobile_phone || '';
      // customer.edit.controller.js
      vm.data.save().then(function(response) {
        $rootScope.$broadcast('clear-cache', {contactperson : {contactperson: true}, customer : {customer: true}});
        return response;
      }).finally(function () {
        vm.contactPersonModePerType[contactPersonType][rowIndex] = 'VIEW';
        vm.contactPersonSendingData[contactPersonType].splice(rowIndex, 1);
      });
    }

    // save regular contact
    function saveContactPerson(contactPersonType, rowIndex) {
      if (vm.contactPersonForms[contactPersonType][rowIndex].$invalid) {
        return;
      }
      if (!angular.isArray(vm.contactPersonSendingData[contactPersonType])) {
        vm.contactPersonSendingData[contactPersonType] = [];
      }
      vm.contactPersonSendingData[contactPersonType][rowIndex] = true;
      // save row, set to view
      // build object to save
      var contactPerson = {
        account_id  : vm.contactPersonData[contactPersonType][rowIndex].customer_number || vm.contactPersonData[contactPersonType][rowIndex].relation_account_id || null,
        type        : contactPersonType || null,
        firstname   : vm.contactPersonData[contactPersonType][rowIndex].firstname || '',
        insertion   : vm.contactPersonData[contactPersonType][rowIndex].insertion || '',
        lastname    : vm.contactPersonData[contactPersonType][rowIndex].lastname || '',
        email       : vm.contactPersonData[contactPersonType][rowIndex].email.correspondence || '',
        phone       : vm.contactPersonData[contactPersonType][rowIndex].phone || '',
        mobile_phone       : vm.contactPersonData[contactPersonType][rowIndex].mobile_phone || ''
      };
      contactPersonService.save(contactPerson, vm.contactPersonModePerType[contactPersonType][rowIndex]).then(function (data) {
        if (data.fields && data.fields.contact_person) {
          vm.contactPersonData[contactPersonType][rowIndex].name = data.fields.contact_person.name;
          if (contactPerson.account_id) {
            $rootScope.$broadcast('wml.portal.custom_message', {level: 'success', code: '1000'});
            contactPersonService.addUpdate(contactPerson.account_id, data.fields.contact_person);
          } else {
            vm.contactPersonData[contactPersonType][rowIndex].customer_number = data.fields.contact_person.relation_account_id;
            contactPersonService.addUpdate(data.fields.contact_person.relation_account_id, data.fields.contact_person);
          }
        }
        $rootScope.$broadcast('clear-cache', {contactperson : {contactperson: true}, customer : {customer: true}});
      }).finally(function () {
        // stop loader, set row in view mode
        vm.contactPersonModePerType[contactPersonType][rowIndex] = 'VIEW';
        vm.contactPersonSendingData[contactPersonType].splice(rowIndex, 1);
      });
    }

    function goToDatalogger(contractAccountId, contractId) {
      var customerId = storage('customer_number');
      $window.location.href = $window.location.protocol + '//' + registry.hostName + '/data-logger/dashboard?contractAccountId=' + contractAccountId + '&contractId=' + contractId + '&customer=' + customerId;
    }

    function downloadPremiseCsv() {
      $window.location.href = premisesService.getCsvExportUrl();
    }

    // Private functions
    function getCustomer(){
      customerService.getCustomer().then(getCustomerSuccess)
    }

    function getCustomerSuccess(response) {
      vm.data = response;
      vm.month = vm.data.wfl_month ? vm.monthTranslations[Number(vm.data.wfl_month) - 1] : false;
      vm.changedWflAmount = vm.data.wfl_amount || 0;

      if(vm.data.is_company) {
        if(vm.data.contact_persons) {
          // general contact person is used to display the customer account correspondence address
          vm.contactPersonAvailability['general'] = true;
          vm.contactPersonModePerType['general'] = ['VIEW'];
          vm.contactPersonData['general'] = [{
            email: vm.data.email,
            lastname: vm.data.lastname,
            name: vm.data.name,
            phone: vm.data.phone,
            mobile_phone: vm.data.mobile_phone,
          }];
          // store contact person data in array
          for(var i=0; i < vm.data.contact_persons.length; i++) {
            // do not render "general" contact persons.
            // general is replaced by correspondence which is not a contact person but the customer account email
            // show the customer account email in the contact persons section and make it editable
            // if edited it will update the account email and or phone number
            if (vm.data.contact_persons[i].account_relationship_type === 'general') {
              continue;
            }
            // mark type for available
            vm.contactPersonAvailability[vm.data.contact_persons[i].account_relationship_type]  = true;
            // get customer data
            contactPersonService.load(vm.data.contact_persons[i].related_account_id).then(function(data) {
              // store contact person data in array
              if (!angular.isArray(vm.contactPersonData[data.contact_persons[0].account_relationship_type])) {
                vm.contactPersonData[data.contact_persons[0].account_relationship_type] = [];
                vm.contactPersonModePerType[data.contact_persons[0].account_relationship_type] = [];
              }
              vm.contactPersonModePerType[data.contact_persons[0].account_relationship_type].push('VIEW');
              vm.contactPersonData[data.contact_persons[0].account_relationship_type].push(data);
            });
          }
        }
      } else {
        contactPersonService.getAdministrationContactPerson().then(function(data) {
          vm.billing_email  = data.email.correspondence;

          if (!angular.isArray(vm.contactPersonData['administration']) && data.contact_persons && data.contact_persons[0] && data.contact_persons[0].account_relationship_type === 'administration') {
            vm.contactPersonData['administration'] = [data];
            vm.contactPersonModePerType[data.contact_persons[0].account_relationship_type] = ['VIEW'];
          }

          // what it do??
          var updatedRecords  = contactPersonService.getUpdated();
          for(var key in updatedRecords) {
            if(updatedRecords[key].relation_type === 'administration') {
              // todo >> test, need to be array?
              vm.contactPersonData['administration'].push(updatedRecords[key]); // administration can only be 1
              if(angular.isString(updatedRecords[key].email)){
                vm.billing_email  = updatedRecords[key].email;
              }
              if(angular.isObject(updatedRecords[key].email) && angular.isString(updatedRecords[key].email.correspondence)){
                vm.billing_email  = updatedRecords[key].email.correspondence;
              }
            }
          }
        });
      }
    }

    function getPremises() {
      customerService.getPremises().then(getPremiseSuccess);
    }

    function getAddresses() {
      customerService.getAddress().then(getAddressesSuccess);
    }

    function getSendControl() {
      billingInformationService.getSendControl().then(getSendControlSuccess);
    }

    function getPremiseSuccess(response) {
      vm.connectionAddresses = response;
    }

    function getAddressesSuccess(response) {
      vm.correspondenceAddress = response;
    }

    function getSendControlSuccess(response) {
      vm.billing_information  = response;
    }

    activate();

    function activate(){
      getCustomer();
      getPremises();
      getAddresses();
      getSendControl();

      enumService.getInsertions().then(function(data) {
        vm.insertions = data;
      });

      // @TODO-mmy no longer need this? handled in edit func
      if (angular.isDefined($state.current.name) && $state.current.name === "customerSaved") {
        $rootScope.$broadcast('wml.portal.custom_message', {level: 'success', code: '1000'});
      }
    }
  }

  angular
    .module('wml.portal.customer')
    .controller('CustomerDashboardController', CustomerDashboardController);
})();
